import moment from 'moment'
const CURRENCY_RATES_API_ENDPOINT =
  'https://api.coinbase.com/v2/exchange-rates?currency=TWD'
export const CURRENCY_REFERENCE_CONFIG_KEY = 'currency/reference-config'
export const CURRENCY_RATES_DATA_KEY = 'currency/rates-data'
const SELECTABLE_CURRENCIES = ['TWD', 'HKD']
/**
 * 處理 Currency Reference 專用的 Service
 *
 * @export
 * @class CurrencyReferenceService
 */

export default class CurrencyReferenceService {
  /**
   * constructor
   *
   * @param {Function} localForage 從 vue instance 傳入 _vm.$vlf
   * @param {Function} vuexStore 從 vue instance 傳入 _vm.$store
   */
  constructor(localForage, vuexStore) {
    this.localForage = localForage
    this.vuexStore = vuexStore
  }

  static get selectableCurrencies() {
    return SELECTABLE_CURRENCIES
  }

  /**
   * 初始化匯率偏好設定。
   * 將使用者已設定的偏好匯率放進 User 的 vuex index 裡
   *
   * @returns
   * @memberof CurrencyReferenceService
   */
  async initialize() {
    const currencyReferenceConfig =
      (await this.localForage.getItem(CURRENCY_REFERENCE_CONFIG_KEY)) || {}

    if (!currencyReferenceConfig.selectedCurrency) return

    await this.fetchCurrencyData().then((data) => {
      if (data && data.updateTime != currencyReferenceConfig.updateTime) {
        let newRate = data.rates[currencyReferenceConfig.selectedCurrency]
        if (newRate) {
          currencyReferenceConfig.currencyRate = newRate
          currencyReferenceConfig.updateTime = data.updateTime
        }
      }
    })

    this.updateCurrencyReferenceConfig(
      currencyReferenceConfig.selectedCurrency,
      currencyReferenceConfig.currencyRate,
      currencyReferenceConfig.updateTime
    )
  }

  updateCurrencyReferenceConfig(selectedCurrency, currencyRate, updateTime) {
    let config = {
      selectedCurrency,
      currencyRate,
      updateTime
    }
    this.localForage.setItem(CURRENCY_REFERENCE_CONFIG_KEY, config)
    this.vuexStore.dispatch('users/updateCurrencyReferenceConfig', config)
  }

  async fetchCurrencyData() {
    let ratesData = await this.localForage
      .getItem(CURRENCY_RATES_DATA_KEY)
      .then((data) => data)

    if (ratesData) {
      let isDateUpdatedRecently = moment(ratesData.updateTime).isAfter(
        moment().subtract(1, 'day')
      )
      if (isDateUpdatedRecently) return ratesData
    }
    return await fetch(CURRENCY_RATES_API_ENDPOINT)
      .then((response) => {
        console.log(`CURRENCY_RATES_API_FETCHED`)
        return response.json()
      })
      .then((data) => {
        return this.localForage.setItem(
          CURRENCY_RATES_DATA_KEY,
          Object.assign(data.data, { updateTime: moment().toDate() })
        )
      })
      .catch((errors) => {
        console.error(errors)
      })
  }
}
