import siteNavbarContainer from '../components/site/navbar.vue'

export default {
  name: 'site-navbar-container',

  components: {
    siteNavbarContainer
  },

  // mixins: [De4bounceHideMixin],

  // props: {},

  data() {
    return {
      selItem: null,
      isDataInited: false
    }
  },

  computed: {},

  // created() {},

  mounted() {
    this.selItem = this.$refs.container.dataset.selItem

    this.isDataInited = true
  },

  methods: {}
}
