<template lang="pug">
.vc-order-cart-confirmation.odd-container.has-padding(v-if="isFormLoaded")
  b-loading(:active="isLoading")

  cart-item-list(
    :order="order"
    :cart-items="cartItems"
    :shipment-forms.sync="form.shipments"
    :gift-items="giftItems"
    :current-step="currentStep"
    :cart-service="cartService"
    mode="edit"
  )

  add-on-slider(isFetchingFromOrder)

  .form-container
    .form-column.-clean
      .form-section
        promo-code-checker(:order="order")
      .form-section(v-if="isUserSignedIn")
        bonus-deduction-checker(
          :order="order"
          :enable-bonus-deduction.sync="form.enable_bonus_deduction"
        )

    .form-column.-clean
      .section-label {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        price-detail(
          :order="order"
          :selected-shipping-method-id="selectedShippingMethodId"
        )

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(
      @click="confirmItems"
      :class="{ 'is-loading': isLoading }"
    ) {{ actionLocaleText('next_step_is', { content: copyLocaleText('address_and_payment_method') }) }}
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'
import PromoCodeChecker from './promo-code-checker.vue'
import BonusDeductionChecker from './bonus-deduction-checker.vue'
import BackToStoreButton from './back-to-store-button.vue'
import CartConfirmForm from '../../../../shared/forms/cart_confirmation_form.js'
import generateGA4Events from '@services/generate_ga4_events'
import AddOnSlider from '@applicationComponents/add_on/slider.vue'
import useWarningInfo from '@/shared/composables/use_warning_info.js'

export default {
  components: {
    CartItemList,
    PromoCodeChecker,
    BonusDeductionChecker,
    BackToStoreButton,
    AddOnSlider
  },

  mixins: [checkoutFlowMixin],
  // props: {},

  data() {
    return {
      selectedShippingMethodId: undefined,
      form: null,
      isFormLoaded: false
    }
  },

  watch: {
    selectedShippingMethodId() {
      this.errors.clear('shipping_method_id')
    },

    cartItems: {
      handler() {
        // to-do 商品內容調整，原則上不會更動運送方式的演算，原則上應該可以不需要重新計算運送方式
        this.form.generateShipmentsFromCartItemDataArray({
          cartItemDataArray: this.cartItemDataArray,
          oriShipments: this.form.shipments
        })
      }
    }
  },

  computed: {
    giftItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']('gift')
    },

    bonusAdjustments() {
      return this.$store.getters['orders/allAdjustments'].filter(
        (adjustment) =>
          adjustment.title ===
          this.copyLocaleText('reward_program.bonus_credit_deduction')
      )
    },

    productIds() {
      return this.$store.getters['orderItems/fromCurrentOrder']()
        .filter((item) => !item.add_on_item_price_id)
        .reduce((prevValue, currentItem) => {
          const variant = this.$store.getters['productVariants/find'](
            currentItem.variant_id
          )
          prevValue.push(variant?.product_id)
          return prevValue
        }, [])

      return []
    },

    cartItemDataArray() {
      return [this.cartItems, this.giftItems].flat().map((cartItem) => {
        return {
          cartItemId: cartItem.id,
          shippingMethodIds: this.shippingMethodIdsFor(cartItem),
          partnerId: this.partnerIdFor(cartItem)
        }
      })
    },

    shipments() {
      return this.order.shipments.map((orderShipment) => {
        const shipment = this.$store.getters['orderShipments/find'](
          orderShipment.id
        )

        return {
          id: shipment.id,
          shipping_method_id: shipment.shipping_method.id,
          partner_id: shipment.partner_id,
          items: shipment.shipment_items.map((item) => {
            return {
              id: item.id,
              item_id: item.item_id
            }
          })
        }
      })
    }
  },
  // created() {},
  mounted() {
    this.form = new CartConfirmForm(this.order)

    this.form.generateShipmentsFromCartItemDataArray({
      cartItemDataArray: this.cartItemDataArray,
      oriShipments: this.shipments
    })

    this.form.enable_bonus_deduction = this.bonusAdjustments.length > 0

    this._trackCheckoutProgress()

    this.$store.dispatch('ga4Operation', [
      generateGA4Events('begin_checkout', {
        items: this.cartItems,
        variants: this.$store.getters['productVariants/all'],
        value: this.toMoney(this.order.total).amount
      })
    ])

    this.isFormLoaded = true
    useWarningInfo('cart_step_1', this.cartItems)
  },

  methods: {
    backToStore() {
      this.$vlf.getItem('redirect-path').then((path) => {
        Turbolinks.visit(path || '/')
      })
    },

    confirmItems() {
      this.cartService.confirmItems(this.form.sync()).finally(() => {
        this._scrollToTop()
      })
    },

    shippingMethodIdsFor(cartItem) {
      const variant = this.$store.getters['productVariants/find'](
        cartItem.variant_id
      )

      return variant.shipping_methods.map((method) => parseInt(method.id))
    },

    partnerIdFor(cartItem) {
      const variant = this.$store.getters['productVariants/find'](
        cartItem.variant_id
      )

      const product = this.$store.getters['products/find'](variant.product_id)

      return product.partner_id
    }
  }
}
</script>
