import ProductCollectService from '../../../../shared/services/product_collect_service.js'
import JwtManageService from '../../../../shared/services/jwt_manage_service.js'
import FlashMessage from '../../components/common/flash-message.vue'
import TopNotificationBroadcastBase from '@applicationComponents/top_notification/broadcast-base.vue'
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import CurrencyReferenceService from '@services/currency_reference_service.js'

export default {
  name: 'basic-initializer',

  components: {
    FlashMessage,
    TopNotificationBroadcastBase
  },

  data() {
    return {
      autoRefresher: null
    }
  },

  computed: {
    ...mapGetters(['flashMessages', 'currentUser']),

    currentCollections() {
      return this.$store.getters['userCollections/currentCollections']
    },

    collectionsLatestUpdatedAt() {
      return this.$store.getters['userCollections/latestUpdatedAt']
    }
  },

  beforeMount() {
    const IPHONE_SE_WIDTH = 320
    const IPHONE_12_PRO_MAX_WIDTH = 428
    const IPAD_PRO_WIDTH = 1024
    const LEEWAY = 1
    const handler = () => {
      if (!document.hidden) {
        let windowWidth = window.innerWidth
        let isMobile = windowWidth < IPAD_PRO_WIDTH + LEEWAY
        let deviceType = ''

        if (isMobile && windowWidth > IPHONE_SE_WIDTH) {
          deviceType =
            windowWidth > IPHONE_12_PRO_MAX_WIDTH ? 'tablet' : 'iphone'
        } else {
          deviceType = isMobile ? 'iphone-se' : 'desktop'
        }

        this.toggleDevice({
          isMobile,
          deviceType,
          windowWidth
        })
      }
    }

    window.addEventListener('resize', debounce(handler, 200))
    handler()
  },

  mounted() {
    const flashMessages = JSON.parse(this.$el.dataset.flashMessages)

    this.$store.dispatch('setFlashMessages', flashMessages)
    this.initializeProductCollections()
    this._setupAutoRefresher()
    new CurrencyReferenceService(this.$vlf, this.$store).initialize()
  },

  destroyed() {
    clearInterval(this.autoRefresher)
  },

  methods: {
    toggleDevice(device) {
      this.$store.dispatch('toggleDevice', device)
    },

    collectedProducts() {
      return this.$vlf.getItem(ProductCollectService.collectionKey())
    },

    initializeProductCollections() {
      new ProductCollectService(
        this.currentUser.type,
        this.currentCollections,
        this.$vlf,
        this.$store
      )
        .initialize()
        .then(() => {
          this.$vlf.setItem(
            ProductCollectService.collectionKey(),
            this.currentCollections
          )
          this.$vlf.setItem(
            ProductCollectService.collectionLatestUpdatedAtKey(),
            this.collectionsLatestUpdatedAt
          )
        })
    },

    _setupAutoRefresher(interval = 3540) {
      JwtManageService.autoRefreshJwt('user', interval)
      this.autoRefresher = setInterval(() => {
        JwtManageService.autoRefreshJwt('user', interval)
      }, interval * 1000)
    }
  }
}
