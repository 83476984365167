<template lang="pug">
k-marketplace-container.vc-marketplace-partner-show-container(
  v-if="data.isDataInited"
)
  template(#tabs)
    tabs(
      v-model="data.selCustomPageId"
      :custom-pages="customPages"
    )

  template(#body)
    .custom-page-show-content-wrapper
      custom-page-show-container(
        v-for="(customPage, index) in customPages"
        v-if="data.selCustomPageId === customPage.id"
        :key="customPage.id"
        :custom-page="customPage"
      )
</template>

<script setup>
const useTypeValue = 21 // marketplace_partner

import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'skid-composables'
import Tabs from './show_container/tabs.vue'
import kMarketplaceContainer from '../common/k-marketplace-container.vue'
import CustomPageShowContainer from '@applicationComponents/custom_page/show-container.vue'

const store = useStore()
const props = defineProps({
  customPageId: { type: String, required: true }
})

const data = reactive({
  customPageIds: [],
  selCustomPageId: props.customPageId,
  isDataInited: false
})

async function fetchCustomPages() {
  const response = await store.dispatch('customPages/all', {
    search: {
      use_type_eq: useTypeValue
    }
  })

  data.customPageIds = response.data.data.map((row) => row.id)
}

const customPages = computed(() => {
  return data.customPageIds.map((id) => {
    return store.getters['customPages/find'](id)
  })
})

const selCustomPage = computed(() => {
  return store.getters['customPages/find'](data.selCustomPageId)
})

watch(
  computed(() => data.selCustomPageId),
  () => {
    history.pushState(null, '', selCustomPage.value.link_url)
  }
)

onMounted(async () => {
  await fetchCustomPages()

  data.isDataInited = true
})
</script>

<style lang="sass">
.vc-marketplace-partner-show-container
  .custom-page-show-content-wrapper
    min-height: 300px
</style>
