<template lang="pug">
.vc-add-on-slider(v-if="displayEmpty || results.length > 0")
  .top-section
    | {{ copyLocaleText('great_value_add_on_item') }}
  .body-wrapper
    .button.is-odd(@click="smoothScrollHorizontally(-1)")
      icon(icon="mdi:chevron-left")
    .body-section(ref="bodySection")
      radio-card(
        v-for="result in results"
        :key="result.price.id"
        :info="result"
        showBuyButton
        :mainProduct="product"
      )
    .button.is-odd(@click="smoothScrollHorizontally(1)")
      icon(icon="mdi:chevron-right")
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue2'
import { onMounted, ref, computed, watch } from 'vue'
import { useStore } from 'skid-composables'
import RadioCard from './radio-card.vue'
import useAddOnPresentable from '@/shared/composables/use_add_on_presentable.js'

const props = defineProps({
  attachedProductIds: {
    type: Array,
    required: false,
    default: () => []
  },

  isFetchingFromOrder: {
    type: Boolean,
    required: false,
    default: () => false
  },

  displayEmpty: {
    type: Boolean,
    required: false,
    default: () => false
  }
})

const store = useStore()
const { fetchAddOnGroups, cartService, cartItemProductIds, results } =
  useAddOnPresentable(store)
const product = computed(() =>
  store.getters['products/find'](props.attachedProductIds[0])
)

const bodySection = ref(null)

const smoothScrollHorizontally = (step, duration = 250) => {
  const element = bodySection.value
  const unitWidth = element.childNodes[0].clientWidth
  const childLength = element.childNodes.length
  const gap = 8
  const perStep = unitWidth + gap
  const maxWidth = perStep * (childLength - 1)

  let targetPosition = element.scrollLeft + perStep * step
  if (targetPosition > maxWidth) targetPosition = maxWidth
  if (targetPosition < 0) targetPosition = 0
  const start = element.scrollLeft
  const change = targetPosition - start
  let startTime

  function animateScroll(currentTime) {
    if (startTime === undefined) startTime = currentTime
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1)
    element.scrollLeft = start + change * progress
    if (progress < 1) requestAnimationFrame(animateScroll)
  }

  requestAnimationFrame(animateScroll)
}

const fetchFromOrder = () => fetchAddOnGroups(cartItemProductIds.value)

watch(cartItemProductIds, () => {
  if (props.isFetchingFromOrder) fetchFromOrder()
})

onMounted(() => {
  if (props.isFetchingFromOrder) return fetchFromOrder()
  if (props.attachedProductIds.length > 0)
    fetchAddOnGroups(props.attachedProductIds)
})
</script>
