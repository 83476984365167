import ProductSlideshow from '@applicationComponents/product/slideshow.vue'
import ProductInfoCard from '@applicationComponents/product/info-card.vue'
import SectionWrapper from '@/odd_pay/js/components/invoice/section-wrapper.vue'
import Form from 'odd-form_object'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'
import MakePaymentButton from '@/odd_pay/js/components/invoice/make-payment-button.vue'

export default {
  name: 'subscription-show-page-container',

  components: {
    ProductSlideshow,
    ProductInfoCard,
    SectionWrapper,
    MakePaymentButton
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      productId: null,
      variantId: null,
      isInitializing: true,
      form: null,
      postInfo: null
    }
  },

  computed: {
    taxAmount() {
      const amount = this.currentInvoice.amount
      const taxAmountCents = (amount / 1.05).toFixed()
      return parseInt(amount - taxAmountCents)
    },

    zipCodes() {
      return TaiwanZipCodes
    },

    cities() {
      return Object.keys(TaiwanZipCodes)
    },

    areas() {
      if (this.form.address.city && TaiwanZipCodes[this.form.address.city])
        return Object.keys(TaiwanZipCodes[this.form.address.city])

      return []
    },

    currentInvoice() {
      return this.$store.getters['oddPayInvoices/currentSubscriptionInvoice']
    },

    item() {
      return this.currentInvoice.items.filter(
        (item) =>
          item.buyable_type === 'Product::Variant' &&
          String(item.buyable_id) === this.variant.id
      )[0]
    },

    variantPrice() {
      return this.variant.consumer_price * this.item.quantity
    },

    variantOriginalPrice() {
      return this.variant.original_price * this.item.quantity
    },

    errors() {
      return this.currentInvoice.errors
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    images() {
      return this.$store.getters['productImages/forProduct'](this.productId)
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.productId)
    },

    variant() {
      return this.variants[0]
    },

    variantName() {
      const productName = this.variant?.product_name
      const variantName = this.variant?.name
      let result = [productName]
      if (variantName) result.push(` - ${variantName}`)
      return result.join('')
    },

    description() {
      return this.variant.description //|| this.product.description
    },

    availablePaymentMethods() {
      return this.$store.getters['oddPayPaymentMethods/all']
    }
  },

  // created() {},

  async mounted() {
    await this.$store
      .dispatch('users/fetchCurrentSubscriptionInvoice')
      .then((response) => {
        const relationships = response.data.data.relationships
        this.productId = relationships['products']?.data[0]?.id
        this.variantId = relationships['variants']?.data[0]?.id
        this.form = new Form(this.currentInvoice)
      })

    await this.$store
      .dispatch('oddPayPaymentMethods/all', {
        search: {
          enabled_true: 1,
          payment_type_eq: 'subscription',
          usage_scopes_use_type_eq: 'user_purchase'
        }
      })
      .then(() => {
        this.tryPreSelectPaymentMethod()
      })

    this.tryPreSelectZip()

    this.isInitializing = false
  },

  methods: {
    selectPaymentMethod(id) {
      this.$set(this.form, 'payment_method_id', id)
      this.errors.clear('payment_method_id')
    },

    tryPreSelectPaymentMethod() {
      if (this.availablePaymentMethods.length === 1) {
        this.form.payment_method_id = this.availablePaymentMethods[0].id
      }
    },

    addressSelected(type) {
      if (type === 'city') {
        this.form.address.area = ''
        this.form.address.zip = ''
      }

      if (type === 'area') {
        this.form.address.zip =
          this.zipCodes[this.form.address.city][this.form.address.area]
      }
    },

    tryPreSelectZip() {
      if (this.form.address.area)
        this.form.address.zip =
          this.zipCodes[this.form.address.city][this.form.address.area]
    },

    confirmInvoice() {
      return this.$store.dispatch(
        'users/confirmOddPayInvoice',
        this.form.sync()
      )
    }
  }
}
