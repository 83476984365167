import newFormFields from '../components/promoter_member/new-form-fields.vue'
import PromoterMember from '@models/promoter_member.js'
import Form from 'odd-form_object'

export default {
  name: 'promoter-member-register-container',

  components: {
    newFormFields
  },

  mixins: [],

  // props: {}

  data() {
    return {
      isFormLoaded: false,
      form: null
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['promoterMembers/isLoading']
    }
  },

  async mounted() {
    this.initForm()
  },

  methods: {
    initForm() {
      this.form = new Form(new PromoterMember())
      this.isFormLoaded = true
    },

    showAgreement() {
      this.isPromoterAgreementActive = true
    },

    async submitForm() {
      // 註冊由 api 完成，取代原本的 form submit 註冊
      // 此方式可以方便處理前端畫面
      await this.$store.dispatch('promoterMembers/signUp', {
        model: this.form.sync()
      })

      // 完成後 執行登入動作
      await this.$refs.form.submit()
    }
  }
}
