<template lang="pug">
.vc-blog-category-blog-section(v-if="blogIds.length > 0")
  .blog-section-header
    h2.section-title.-larger {{ sectionTitle }}
  .blog-section-body
    b-loading(:active.sync="isLoading")

    .section
      .left
        blog-card.large.vertical(
          v-if="firstBlog"
          :key="firstBlog.id"
          :blog="firstBlog"
        )
      .right
        blog-card.small.horizontal(
          v-for="blog in sideBlogs"
          :key="blog.id"
          :blog="blog"
        )
</template>

<script>
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard
  },

  // mixins: [],

  props: {
    category: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      blogIds: [],
      isLoading: true
    }
  },

  computed: {
    sectionTitle() {
      if (this.category) {
        return this.category.name
      } else {
        return this.copyLocaleText('latest_blog')
      }
    },

    blogs() {
      return this.blogIds.map((id) =>
        this.$store.getters['promotionalArticles/find'](id)
      )
    },

    firstBlog() {
      return this.blogs[0]
    },

    sideBlogs() {
      return this.blogs.slice(1, 4)
    }
  },

  created() {
    if (this.category) {
      this._fetchArticlesByCategory()
    } else {
      this._fetchArticles()
    }
  },

  // mounted() {},

  methods: {
    _fetchArticles() {
      this.$store
        .dispatch('promotionalArticles/all', {
          pageNumber: 1,
          pageSize: 4,
          sort: '-published_at',
          usage: 'blog',
          forSearch: true
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    },

    _fetchArticlesByCategory() {
      this.$store
        .dispatch('categorySystems/fetchCategoryPromotionalArticles', {
          model: this.category,
          options: {
            pageNumber: 1,
            pageSize: 4,
            sort: '-published_at',
            // skipTopOrder: true,
            forSearch: true,
            sub_categories_included: true
          }
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
          this.isLoading = false
        })
    }
  }
}
</script>
