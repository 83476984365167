import PriceDetail from '../order/price-detail.vue'
import reject from 'lodash.reject'

export default {
  components: {
    PriceDetail
  },

  props: {
    order: {
      type: Object,
      required: true
    },

    cartItems: {
      type: Array,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    currentStep: {
      type: Number,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    isLoading() {
      return (
        this.$store.getters['users/isLoading'] ||
        this.$store.getters['orders/isLoading']
      )
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    shippingMethodFormOrderShipment() {
      if (this.order.shipments.length > 0)
        return this.$store.getters['orderShipments/find'](
          this.order.shipments[0].id
        ).shipping_method
    },

    shippingMethod() {
      return this.$store.getters['shippingMethods/find'](
        this.shippingMethodFormOrderShipment.id
      )
    },

    paymentMethodFromOrderPayment() {
      if (this.order.payment)
        return this.$store.getters['orderPayments/find'](this.order.payment.id)
          .payment_method
    },

    paymentMethods() {
      // 根據已選擇的運送方式來決定 貨到付款 這個付款方式是否出現
      let paymentMethods = this.$store.getters['paymentMethods/allEnabled']

      if (!this.shippingMethod.shipment_type_detail.collectable) {
        paymentMethods = reject(
          paymentMethods,
          (method) => method.payment_type === 'cash_on_delivery'
        )
      }

      return paymentMethods
    },

    orderPayment() {
      return this.$store.getters['orderPayments/find'](this.order.payment.id)
    },

    orderInvoice() {
      return this.$store.getters['orders/findInvoice'](
        this.orderPayment.invoice.id
      )
    },

    creditNotes() {
      return this.$store.getters['orders/allCreditNotes']
    }
  },

  methods: {
    _tryFetchPaymentMethods() {
      if (this.paymentMethods.length === 0) {
        return this.$store.dispatch('paymentMethods/all', {
          sort: 'position'
        })
      } else {
        return Promise.resolve(true)
      }
    },

    _scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },

    _trackCheckoutProgress() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:setAction',
            'checkout',
            {
              step: this.currentStep
            }
          ],
          ...this.cartItems.map((cartItem) => {
            const productVariant = this.$store.getters['productVariants/find'](
              cartItem.variant_id
            )
            return [
              'ec:addProduct',
              {
                type: 'addProduct',
                id: productVariant.sku,
                name: productVariant.product_name,
                brand: '',
                category: '',
                variant: productVariant.name,
                price: this.toMoney(cartItem.price, { isExchange: false })
                  .amount,
                quantity: cartItem.quantity
              }
            ]
          }),
          [
            'send',
            {
              hitType: 'pageview',
              page: `/cart/step${this.currentStep}`
            }
          ]
        ])
        .then((_) => {
          const stepMapForPixel = [
            null,
            'InitiateCheckout', // Step 1
            'AddPaymentInfo', // Step 2
            null
          ]
          const operation = stepMapForPixel[this.currentStep]

          if (!operation) return
          this.$store.dispatch('pixelOperation', [
            [
              'track',
              operation,
              {
                currency: 'TWD',
                num_items: this.cartItems.length,
                value: this.toMoney(this.order.total, { isExchange: false })
                  .amount
              }
            ]
          ])
        })
    }
  }
}
