import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'impressions',
  attributes: ['id', 'created_at', 'updated_at'],
  editableAttributes: []
}

export default class Impression extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static create({ impressionableType, impressionableId, uuid }) {
    const requestBody = {
      data: {
        type: 'impression-create',
        attributes: {
          impressionable_type: impressionableType,
          impressionable_id: impressionableId,
          uuid: uuid
        }
      }
    }

    return axios.post(`${new this().apiBasePath()}`, requestBody)
  }
}
