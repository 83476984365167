<template lang="pug">
.vc-layout-navbar-logo-bar
  .mobile-trigger-wrapper
    mobile-trigger-button(:active.sync="mobileActive")
  .logo-wrapper
    img.image(:src="currentLogoPath")
  .user-option-trigger-wrapper
    user-option-trigger(:login-url="loginUrl")
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'skid-composables'
import mobileTriggerButton from './logo_bar/mobile-trigger-button.vue'
import AiLogo from '@application/images/navbar/ai-logo.svg'
import UserOptionTrigger from './logo_bar/user-option-trigger.vue'

const MOBILE_IMAGE_BREAK_POINT = 769
const props = defineProps({
  currentTab: { type: String },
  logoPath: { type: String },
  mobileLogoPath: { type: String }
})

const store = useStore()
const mobileActive = ref(false)

const inputHandler = (value) => {
  emit('input', value)
}

const isMobile = computed(() => {
  // workaround for safari can't get window width from vuex at first time loading
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_IMAGE_BREAK_POINT
  )
})

const currentLogoPath = computed(() => {
  return isMobile.value
    ? props.mobileLogoPath || AiLogo
    : props.logoPath || AiLogo
})

const loginUrl = computed(() => {
  switch (props.currentTab) {
    case 'kol':
      return '/promoters/login'
    case 'partner':
      return '/partner/login'
    default:
      return '/user/login'
  }
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'

.vc-layout-navbar-logo-bar
  --logo-width-small: 165px
  --logo-width-mobile: 195px
  --logo-width: 350px

  height: 100% // decided by parent's container
  display: grid
  grid-template-columns: max-content var(--logo-width-small) 1fr
  align-items: center
  padding-top: 1.5rem
  align-items: flex-start

  overflow: hidden
  +utils.from(size.$iphone)
    grid-template-columns: max-content var(--logo-width-mobile) 1fr
  +utils.from(size.$ipad)
    grid-template-columns: 1fr var(--logo-width) 1fr
  .mobile-trigger-wrapper
    display: flex
    justify-content: center
    align-items: center
    +utils.from(size.$ipad)
      display: none
  .logo-wrapper
    grid-column: 2
    display: flex
    +utils.from(size.$ipad)
      justify-content: center
      padding-bottom: 3rem
      height: 75%
  .user-option-trigger-wrapper
    place-self: start end
</style>
