<template lang="pug">
.vc-dashboard-level-info-button(v-if="hasMemberLevel")
  button.button.info-button.is-odd(@click="openInfo")
    span {{ buttonText }}
    .icon
      i.fa.fa-info-circle
</template>

<script>
import LevelInfoDetail from './level-info-detail.vue'

export default {
  components: {
    LevelInfoDetail
  },
  // mixins: [],
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    hasMemberLevel() {
      return this.memberLevels.length > 0
    },

    memberLevels() {
      return this.$store.getters['memberLevels/all']
    },

    buttonText() {
      return this.user.member_level || '會員等級'
    }
  },
  // created() {},
  async mounted() {
    await this._ensureMemberLevelsLoaded()
  },

  methods: {
    _ensureMemberLevelsLoaded() {
      if (this.hasMemberLevel) return

      return this.$store.dispatch('memberLevels/all', { sort: '-id' })
    },

    openInfo() {
      this.$buefy.modal.open({
        parent: this,
        component: LevelInfoDetail,
        props: {
          user: this.user
        }
      })
    }
  }
}
</script>
