<template lang="pug">
.vc-product-list
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .tools-wrapper
    search-box(
      @update:search-options="searchOptions.variants_with_master_sku_or_brand_name_or_name_cont = $event"
      @update:search-options-ready="searchOptionsReady = $event"
    )
    sort-button(
      :sort-field="sortField"
      :sort-order="sortOrder"
      @sort-option-updated="sortOptionUpdatedHandler"
    )

  .decoration-stripe.-one

  product-tag-filter(
    @update:tag-with="tagWith = $event"
    @update:tag-ready="tagReady = $event"
    :allow-age-restricted-tags="isAgeRestricted"
  )

  // .decoration-stripe.-one.no-margin

  .list-wrapper
    product-card(
      v-for="(product, index) in products"
      :product="product"
      :index="index"
      :key="product.id"
      :list-location="listLocation"
      :entry-path="entryPath"
      @ga-impressed="impressedHandler"
      id-prefix="index"
    )

  odd-pagination(
    :total="totalCount"
    :current.sync="currentPage"
    :per-page="pageSize"
    size="is-small"
    order="is-centered"
    :rounded="true"
    @change="onPageChange"
  )
</template>

<script>
import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import SearchBox from '../../components/product/search-box.vue'
import SortButton from '../../components/product/sort-button.vue'
import ProductTagFilter from '../../components/product/tag-filter.vue'
import ProductCard from './card.vue'
import OddPagination from '../common/odd-pagination.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    SearchBox,
    SortButton,
    ProductTagFilter,
    ProductCard,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin, productImpressionMixin],

  props: {
    category: {
      type: Object,
      required: false
    },

    isAgeRestricted: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data() {
    return {
      resourceType: 'products',
      currentPage: 1,
      pageSize: 24,
      sortOrder: 'asc',
      sortField: 'created_at',
      tagReady: false,
      tagWith: [],
      searchOptionsReady: false,
      searchOptions: {
        variants_with_master_sku_or_brand_name_or_name_cont: undefined
      },
      entryPath: undefined
    }
  },

  computed: {
    isLoading() {
      return (
        this.$store.getters['productCategories/isLoading'] ||
        this.$store.getters['products/isLoading']
      )
    },

    /**
     *
     * @returns {string | null} scope keywords of product model
     */
    productFilter() {
      if (
        !!this.searchOptions[
          'variants_with_master_sku_or_brand_name_or_name_cont'
        ]
      )
        return null
      return this.isAgeRestricted ? 'age_restricted' : 'regular_content'
    },

    products() {
      return this.$store.getters['products/all']
    },

    additionalOptions() {
      return {
        tag: encodeURIComponent(this.tagWith.join(','))
      }
    },

    currentPath() {
      if (this.category) {
        return `/product-categories/${
          this.category.slug || this.category.id
        }/products`
      } else {
        if (window.location.pathname === '/adults-only') return '/adults-only'
        return '/product-categories'
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    listLocation() {
      if (this.category) {
        let result = this.category.name
        if (this.currentSubCategory)
          result += ` > ${this.currentSubCategory.name}`

        return `Category - ${result}`
      } else {
        return 'Products Page'
      }
    },

    filterComponentsReady() {
      return this.searchOptionsReady && this.tagReady
    }
  },

  watch: {
    // 等待 tagFilter 與 searchBox 的資訊都從 child components 更新至 parent 時才一併送出資料請求
    filterComponentsReady: {
      handler() {
        this.fetchingInitialData()
      }
    },

    category: {
      handler() {
        if (this.filterComponentsReady) this.processDataWithNewQuery()
      }
    },

    tagWith() {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    },

    'searchOptions.variants_with_master_sku_or_brand_name_or_name_cont'() {
      if (this.filterComponentsReady) this.processDataWithNewQuery()
    }
  },

  mounted() {
    this.entryPath = window.location.pathname
    this._initDefaultSortingOptions()
  },

  methods: {
    sortOptionUpdatedHandler(field, order) {
      this.onSort(field, order)
    },

    async fetchData(options) {
      this.currentPage = options.pageNumber
      options.filter = this.productFilter

      if (this.category) {
        await this._fetchCategoryProducts(options)
      } else {
        await this._fetchProducts(options)
      }

      await this.$store.dispatch('ga4Operation', [
        generateGA4Events('view_item_list', {
          items: this.products,
          item_list_name: this.listLocation
        })
      ])
    },

    _fetchCategoryProducts(options) {
      return this.$store.dispatch(`productCategories/fetchProducts`, {
        model: this.category,
        options: Object.assign(options, {
          sub_categories_included: true
        })
      })
    },

    _fetchProducts(options) {
      return this.$store.dispatch('products/all', Object.assign(options))
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.tag) result += `&tag=${decodeURIComponent(options.tag)}`

      return result
    },

    checkAdditionalOptionsFromUrl() {
      return {
        tag: encodeURIComponent(this.tagWith.join(','))
      }
    },

    processDataWithNewQuery() {
      this.currentPage = 1
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
      this._scrollToListTop()
    },

    _initDefaultSortingOptions() {
      const productIndexConfig =
        this.$store.getters['siteConfigs/siteCustomDisplayConfig'].product_index

      this.sortOrder = productIndexConfig.default_sort_order
      this.sortField = productIndexConfig.default_sort_field
    }
  }
}
</script>
