<template lang="pug">
.vc-site-navbar(:class="{ fixed: navbarState.isFixedMode }")
  .content-wrapper
    logo-bar.logo-bar-content(
      :current-tab="currentTab"
      :logo-path="logoPath"
      :mobile-logo-path="mobileLogoPath"
    )

    items-tab.item-tab-element(
      :current-tab="currentTab"
      :items="items"
      :is-fixed-mode="navbarState.isFixedMode"
    )
</template>

<script setup>
import { computed, reactive, watch, onMounted, ref } from 'vue'
import { useStore, useToast, useI18n } from 'skid-composables'
import logoBar from './navbar/logo-bar.vue'
import itemsTab from './navbar/items-tab.vue'
import { useScrollTrigger } from '@shared/composables/use_scroll_watcher.js'

const ITEMS_MAP = [
  {
    label: '媒合中心',
    value: 'kol',
    iconPack: 'mdi',
    icon: 'cards',
    links: [
      '/marketplace/kol',
      '/marketplace/kol/main',
      '/marketplace/kol/match'
    ]
  },
  {
    label: '品牌案例',
    value: 'partner',
    iconPack: 'mdi',
    icon: 'book',
    links: [
      '/marketplace/partner',
      '/marketplace/partner/book',
      '/marketplace/partner/match'
    ]
  },
  {
    label: '官方商城',
    value: 'products',
    iconPack: 'mdi',
    icon: 'cart',
    links: [
      '/marketplace/product',
      '/products',
      '/product-categories',
      '/cart'
    ]
  },
  {
    label: '選務尚誌',
    value: 'klog',
    iconPack: 'mdi',
    icon: 'bookmark-box-multiple',
    links: ['/blog']
  }
]

const props = defineProps({
  logoPath: { type: String },
  mobileLogoPath: { type: String }
})

const { isAboveThreshold } = useScrollTrigger({
  targetClass: 'logo-bar-content'
})
const store = useStore()

const navbarState = computed(() => {
  const state = store.getters['navbarState']

  return {
    isFixedMode: state.isFixedMode
  }
})

const setNavbarIsFixedMode = () => {
  store.dispatch('setNavbarState', {
    isFixedMode: isAboveThreshold.value
  })
}

watch(isAboveThreshold, setNavbarIsFixedMode)

onMounted(() => {
  setNavbarIsFixedMode()
})

//- items
const items = computed(() => {
  return ITEMS_MAP
})

const currentTab = computed(() => {
  return (
    items.value.find((item) => {
      return item.links.some((link) => window.location.href.includes(link))
    })?.value || ''
  )
})
</script>

<style lang="sass">
@use '@application/css/base/variables.sass'
@use '@shared/css/utils'
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'

$html-padding: variables.$v-navbar-height
$logo-bar-height: 130px
$items-tab-height: 40px

$mobile-html-padding: variables.$v-navbar-mobile-height
$mobile-logo-bar-height: 90px
$mobile-items-tab-height: 110px
// $mobile-quick-links-wrapper-height: 120px

.vc-site-navbar
  --c-white: #{color.$white}
  --fz-small: ${font.$small}
  --border-color: hsl(197deg 100% 50%)
  --border-color-lighten: hsl(188deg 100% 50%)
  --nav-margin-bottom: 2rem

  height: calc(#{$mobile-logo-bar-height} + #{$mobile-items-tab-height} - #{$html-padding})
  margin-bottom: var(--nav-margin-bottom)
  +utils.from(size.$ipad)
    height: calc(#{$logo-bar-height} + #{$items-tab-height} - #{$html-padding})
  .content-wrapper
    top: $mobile-html-padding * -1
    +utils.from(size.$ipad)
      top: $html-padding * -1
    position: relative
    background: color.$white
    .logo-bar-content
      height: $mobile-logo-bar-height
      padding-bottom: 1.75rem
      +utils.from(size.$ipad)
        padding-bottom: 0
        height: $logo-bar-height
    .item-tab-element
      height: $mobile-items-tab-height
      +utils.from(size.$ipad)
        height: $items-tab-height
  &.fixed
    .logo-bar-content
      display: none
    .content-wrapper
      position: fixed
      top: 0
      left: 0
      width: 100%
      z-index: 100
</style>
