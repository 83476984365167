import salesEventMixin from '../../components/mixins/sales_event_mixin.js'
import SalesEventProductList from '../../components/sales_event/product-list.vue'
import SlideCarouselContainer from '@applicationComponents/slide/carousel-container.vue'

export default {
  name: 'sales-event-show-page-container',

  components: {
    SalesEventProductList,
    SlideCarouselContainer
  },

  mixins: [salesEventMixin],

  // props: {},

  data() {
    return {
      salesEventId: null,
      isLoaded: false,
      slidesReady: false
    }
  },

  computed: {
    salesEvent() {
      return this.$store.getters['salesEvents/find'](this.salesEventId)
    },

    isLoading() {
      return !this.isLoaded
    }
  },

  // created() {},

  async mounted() {
    this.salesEventId = this.$el.dataset.salesEvent

    try {
      await this.$store.dispatch('salesEvents/find', this.salesEventId)
      this.isLoaded = true
      await this.tryRestoreScrollPosition()
    } catch (_error) {
      this.isLoaded = true
    }
  }

  // methods: {},
}
