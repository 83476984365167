<template lang="pug">
.vc-custom-page-show-page-container
  .items
    .item(v-for="(section, index) in pageSections")
      section-view(
        :key="index"
        :custom-page-section="section"
      )
</template>

<script setup>
import { reactive, computed, onMounted, ref } from 'vue'
import { useStore } from 'skid-composables'
import SectionView from './show_container/section-view.vue'

const props = defineProps({
  customPage: { type: Object, required: true }
})

const store = useStore()
const data = reactive({
  isLoaded: false
})

const pageSections = computed(() => {
  return store.getters['customPageSections/filterBy']({
    page_id: parseInt(props.customPage.id)
  }).sort((a, b) => a.position - b.position)
})

const getDetailData = () => {
  return store.dispatch('customPages/find', props.customPage.id)
}

onMounted(async () => {
  await getDetailData()

  data.isLoaded = true
})
</script>

<style lang="sass">
.vc-custom-page-show-page-container
  min-height: 300px
  padding-bottom: 3rem
</style>
