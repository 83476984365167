import { ref, onMounted, onBeforeUnmount } from 'vue'

export function useScrollTrigger({ targetClass }) {
  const isAboveThreshold = ref(false)
  let triggerHeight = 0

  const queryScroll = () => {
    const currentScrollPosition = window.scrollY

    isAboveThreshold.value = currentScrollPosition > triggerHeight
  }

  const listenScroll = () => {
    const targetElement = document.getElementsByClassName(targetClass)[0]

    triggerHeight = targetElement.offsetHeight

    window.addEventListener('scroll', queryScroll)
  }

  const cleanupScroll = () => {
    window.removeEventListener('scroll', queryScroll)
  }

  onMounted(() => {
    listenScroll()

    queryScroll()
  })

  onBeforeUnmount(() => {
    cleanupScroll()
  })

  return {
    isAboveThreshold
  }
}
