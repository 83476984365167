<template lang="pug">
.vc-dashboard-user-profile-form
  .section-label
    .icon
      i.fa.fa-id-card-o
    span {{ copyLocaleText('user_profile_management') }}

  .form-section.has-sub-fields.profile-info
    b-field(
      :label="attributeLocaleText('user/profile', 'name')"
      :type="errors.errorClassAt('name')"
      :message="errors.get('name')"
    )
      b-input(
        name="name"
        autocomplete="name"
        v-model="form.profile.name"
        @input="errors.clear('name')"
      )

    b-field(
      :label="attributeLocaleText('user/profile', 'phone')"
      :type="errors.errorClassAt('phone')"
      :message="errors.get('phone')"
      :addons="false"
    )
      b-field(
        :type="errors.errorClassAt('verification_code') || user.phoneVerifiedType()"
        :message="errors.get('verification_code') || enumLocaleText('user/profile', 'phone_verified', user.profile.phone_verified)"
      )
        b-field.phone-field(
          :type="errors.errorClassAt('phone') || user.phoneVerifiedType()"
        )
          b-input(
            type="tel"
            autocomplete="tel"
            name="tel"
            v-model="form.profile.phone"
            expanded
            @input="errors.clear('phone')"
          )
          p.control
            get-phone-verification-code-button(
              v-if="isSmsServiceEnabled"
              :form="form"
              :user="user"
              :show-verify-phone-button.sync="showVerifyPhoneButton"
            )

      b-field.phone-field(
        v-if="showVerifyPhoneButton"
        :label="actionLocaleText('enter_verification_code')"
      )
        verify-phone-button(
          :user="user"
          :errors="errors"
          :show-verify-phone-button.sync="showVerifyPhoneButton"
        )

    b-field(
      :label="attributeLocaleText('user/profile', 'gender')"
      :type="errors.errorClassAt('gender')"
      :message="errors.get('gender')"
    )
      b-select(
        v-model="form.profile.gender"
        autocomplete="gender"
        name="gender"
        @input="errors.clear('gender')"
      )
        option(value="male") {{ enumLocaleText('user/profile', 'gender', 'male') }}
        option(value="female") {{ enumLocaleText('user/profile', 'gender', 'female') }}

    b-field(
      :label="attributeLocaleText('user/profile', 'birthday')"
      :type="errors.errorClassAt('birthday')"
      :message="errors.get('birthday')"
    )
      b-field
        b-datepicker(
          v-model="form.profile.birthday"
          :mobile-native="false"
          :max-date="new Date()"
        )

    b-field(
      :label="attributeLocaleText('user/profile', 'company') + ` (${messageLocaleText('optional')})`"
      :type="errors.errorClassAt('company')"
      :message="errors.get('company')"
    )
      b-input(
        name="company"
        autocomplete="organization"
        v-model="form.profile.company"
        @input="errors.clear('company')"
      )
    b-field(
      :label="attributeLocaleText('user/profile', 'ein') + ` (${messageLocaleText('optional')})`"
      :type="errors.errorClassAt('ein')"
      :message="errors.get('ein')"
    )
      b-input(
        v-model="form.profile.ein"
        autocomplete="ein"
        name="ein"
        @input="errors.clear('ein')"
      )
  .section-label
    .icon
      i.fa.fa-map-marker
    span {{ copyLocaleText('resident_address') }}

  .form-section.has-sub-fields
    b-field(
      :label="attributeLocaleText('user/profile', 'city')"
      :type="errors.errorClassAt('city')"
      :message="errors.get('city')"
    )
      b-select(
        v-model="form.profile.address.city"
        autocomplete="locality"
        @input="errors.clear('city')"
      )
        option(
          value=""
          disabled
          selected
        ) {{ actionLocaleText('choose_one') }}
        option(
          v-for="(city, index) in cities"
          :key="index"
          :value="city"
        ) {{ city }}
    b-field(
      :label="attributeLocaleText('user/profile', 'area')"
      :type="errors.errorClassAt('area')"
      :message="errors.get('area')"
    )
      b-select(
        v-model="form.profile.address.area"
        autocomplete="region"
        @input="errors.clear('area')"
      )
        option(
          value=""
          disabled
          selected
        ) {{ actionLocaleText('choose_one') }}
        option(
          v-for="(area, index) in areas"
          :key="index"
          :value="area"
        ) {{ area }} ({{ getZipCode(area) }})

    b-field.is-full(
      :label="attributeLocaleText('user/profile', 'street_address')"
      :type="errors.errorClassAt('street_address')"
      :message="errors.get('street_address')"
    )
      b-input(
        v-model="form.profile.address.street_address"
        autocomplete="street-address"
        @input="errors.clear('street_address')"
      )

  button.button.is-odd.is-block(
    @click="submitForm"
    :class="{ 'is-loading': isLoading }"
  ) {{ actionLocaleText('submit') }}
</template>

<script>
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'
import GetPhoneVerificationCodeButton from './profile/get-phone-verification-code-button.vue'
import VerifyPhoneButton from './profile/verify-phone-button.vue'
import UserForm from '../../../../shared/forms/user_form.js'

export default {
  components: {
    GetPhoneVerificationCodeButton,
    VerifyPhoneButton
  },
  // mixins: [],
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      zipCodes: TaiwanZipCodes,
      form: new UserForm(this.user),
      showVerifyPhoneButton: false
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    cities() {
      return Object.keys(TaiwanZipCodes)
    },

    areas() {
      if (
        this.form.profile.address.city &&
        TaiwanZipCodes[this.form.profile.address.city]
      )
        return Object.keys(TaiwanZipCodes[this.form.profile.address.city])
    },

    siteFeatureConfigs() {
      return this.$store.getters['siteConfigs/allConfigs'].site_feature_configs
    },

    isSmsServiceEnabled() {
      return this.siteFeatureConfigs?.sms_service_configs?.enabled
    }
  },

  watch: {
    'form.profile.address.city': {
      handler(newCity) {
        if (TaiwanZipCodes[newCity][this.form.profile.address.area]) return

        this.form.profile.address.area = ''
        this.form.profile.address.zip = ''
      }
    },

    'form.profile.address.area': {
      handler(newArea) {
        if (newArea)
          this.form.profile.address.zip =
            TaiwanZipCodes[this.form.profile.address.city][
              this.form.profile.address.area
            ]
      }
    }
  },

  // created() {},
  mounted() {
    this.getFeatureSiteConfigs()
  },

  methods: {
    getFeatureSiteConfigs() {
      if (!this.siteFeatureConfigs)
        this.$store.dispatch('siteConfigs/getSiteFeatureConfigs')
    },

    dateParser(date) {
      if (date) return new Date(date * 1000)
      return new Date()
    },

    getZipCode(area) {
      return TaiwanZipCodes[this.form.profile.address.city][area]
    },

    submitForm() {
      this.$store.dispatch('users/updateProfile', this.form.sync()).then(() => {
        return this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('resource_updated_successfully', {
            resource: this.attributeLocaleText('user', 'profile')
          })
        ])
      })
    }
  }
}
</script>
