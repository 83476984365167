import FbMessenger from '../components/common/fb-messenger.vue'
import LineAtLink from '@applicationComponents/common/line-at-link.vue'

export default {
  name: 'fb-messenger-container',

  components: {
    FbMessenger,
    LineAtLink
  }
}
