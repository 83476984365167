<template lang="pug">
.vc-order-summary-unit
  number-and-placed-date(:order="order")

  state-list(:order="order")

  .order-price-info
    span.title
      span {{ attributeLocaleText('order', 'total') }}
      span.items-count {{ copyLocaleText('total_items_count', { count: order.items.length }) }}
      //- span.tax-note {{ attributeLocaleText('order', 'include_business_tax', { tax: toMoney(order.tax_total, { isExchange: false }).format() }) }}
    span.price(data-currency="TWD") {{ toMoney(order.total, { isExchange: false }).format() }}

  option-buttons(
    :order="order"
    :cart-service="cartService"
  )
</template>

<script>
import NumberAndPlacedDate from './number-and-placed-date.vue'
import StateList from './state-list.vue'
import OptionButtons from './option-buttons.vue'

export default {
  components: {
    NumberAndPlacedDate,
    StateList,
    OptionButtons
  },

  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isLoading() {
      return (
        this.$store.getters['users/isLoading'] ||
        this.$store.getters['orders/isLoading']
      )
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
