<template lang="pug">
.vc-marketplace-partner-show-container-tabs
  .left
    .logo-wrapper(v-if="navbarState.isFixedMode")
      img.image(:src="AiLogo")
  .center
    .pc-tabs-wrapper
      k-tabs(
        style-type="kolnet-text"
        :value="value"
        @input="inputHandler"
      )
        b-tab-item(
          v-for="(customPage, index) in customPages"
          :key="index"
          :label="customPage.name"
          :value="customPage.id"
        )
    .mobile-tabs-wrapper
      k-dropdown.mobile-tabs(
        style-type="kolcenter"
        :value="value"
        :items="items"
        expanded
        @input="inputHandler"
      )
  //- .right
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import KTabs from '@sharedComponents/common/k-tabs.vue'
import KDropdown from '@sharedComponents/common/k-dropdown.vue'
import AiLogo from '@application/images/navbar/ai-logo.svg'

// icon: ticket, wallet-giftcard, cards

const props = defineProps({
  value: { type: [Number, String] },
  customPages: { type: Array, required: true },
  isFixedMode: { type: Boolean }
})

const emit = defineEmits(['input'])
const store = useStore()

const inputHandler = (value) => {
  emit('input', value)
}

const items = computed(() => {
  return props.customPages.map((customPage) => {
    return {
      value: customPage.id,
      label: customPage.name
    }
  })
})

const navbarState = computed(() => {
  const state = store.getters['navbarState']

  return {
    isFixedMode: state.isFixedMode
  }
})

// onMounted(() => {})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.vc-marketplace-partner-show-container-tabs
  width: 100%
  height: 100%
  +utils.from(size.$ipad)
    display: grid
    grid-template-columns: 1fr auto 1fr
    align-items: center
  position: relative
  padding: 0 1rem
  +utils.from(size.$ipad)
    padding: 0 50px
  .left
    display: none
    +utils.from(size.$ipad)
      display: block
    .logo-wrapper
      height: 30px
      img
        height: 100%
  .center
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    .k-tabs .tabs ul
      gap: 100px
    .pc-tabs-wrapper
      display: none
      +utils.from(size.$ipad)
        display: block
    .mobile-tabs-wrapper
      width: 100%
      +utils.from(size.$ipad)
        display: none
  // .right
</style>
