export const FETCH_IMPRESSIONS_SUCCESS = 'FETCH_IMPRESSIONS_SUCCESS'
export const GET_RELATED_IMPRESSIONS_SUCCESS = 'GET_RELATED_IMPRESSIONS_SUCCESS'
export const GET_IMPRESSION_SUCCESS = 'GET_IMPRESSION_SUCCESS'
export const ADD_IMPRESSION_SUCCESS = 'ADD_IMPRESSION_SUCCESS'
export const UPDATE_IMPRESSION_SUCCESS = 'UPDATE_IMPRESSION_SUCCESS'
export const DELETE_IMPRESSION_SUCCESS = 'DELETE_IMPRESSION_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
