<template lang="pug">
.partner-register-form-account-section.section
  .title
    | {{ pageTitleLocaleText('partner', 'profile', 'account') }}
  .body
    partner-account-fields(
      :form="form"
      :errors="errors"
    )
</template>

<script>
import { defineComponent, reactive, ref, computed } from 'vue'
import PartnerAccountFields from '@sharedComponents/partner/common/account-fields.vue'

export default defineComponent({
  components: {
    PartnerAccountFields
  },

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const errors = computed(() => props.form.model.errors)

    return {
      errors
    }
  }
})
</script>
