<script lang="ts">
import { defineComponent } from 'vue'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'

export default defineComponent({
  mixins: [backendPaginateFilterSortAndSearchableMixin],
  data() {
    return {
      isPositioningLisTableActive: false,
      availableFilters: ['short.ready'],
      resourceType: 'videos',
      sortOrder: 'desc',
      sortField: 'created_at'
    }
  },
  mounted() {
    this.fetchingInitialData()
  }
})
</script>

<script setup lang="ts">
import { Icon } from '@iconify/vue2'
import { useStore } from 'skid-composables'
import { useModal, useInstance } from 'skid-composables'
import VideoModal from './video-modal.vue'
import { computed } from 'vue'

const instance = useInstance()
const store = useStore()

const videos = computed(() => {
  return store.getters['videos/all']
})
const isReadyToShow = computed(() => {
  return videos.value.length > 0
})

function openVideoModal(video: Object) {
  useModal({
    parent: instance,
    component: VideoModal,
    props: {
      video,
      canCancel: ['x', 'escape', 'outside']
    }
  })
}
</script>

<template>
  <div v-if="isReadyToShow" class="vc-latest-videos">
    <ul class="video-slider">
      <li
        v-for="video in videos"
        :key="`video-unit-${video.id}`"
        class="video-unit"
        @click="openVideoModal(video)">
        <div class="play-button-wrapper">
          <div class="play-button">
            <Icon icon="pepicons-pop:play-circle-filled" />
          </div>
        </div>
        <img :alt="video.title" :src="video.thumbnail_url" />
      </li>
    </ul>
  </div>
</template>

<style scoped>
.vc-latest-videos {
  --top-spacing: 2.375rem;
  --video-unit-spacing: 1rem;
  --video-unit-width: 260px;
  --video-aspect-ratio: 1 / 1.777;
  --video-border-radius: 1rem;
  --overlay-color: hsl(0 0% 0% / 50%);
  --button-color: hsl(197 100% 50% / 100%);
  --button-shadow: drop-shadow(0 0 1rem var(--button-color));

  padding-top: var(--top-spacing);

  .video-slider {
    display: flex;
    gap: var(--video-unit-spacing);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .video-unit {
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: var(--video-border-radius);
    overflow: hidden;
    &:hover {
      --overlay-color: hsl(0, 0%, 0%, 25%);
      --button-shadow: drop-shadow(0 0 0.1rem hsl(0 0% 0% / 75%));

      .play-button {
        scale: 1.1;
      }
    }

    > img {
      display: block;
      width: var(--video-unit-width);
      aspect-ratio: var(--video-aspect-ratio);
      object-fit: cover;
    }
  }

  .play-button-wrapper {
    position: absolute;
    display: grid;
    place-items: center;
    inset: 0;
    background-color: var(--overlay-color);
    transition: background-color 0.25s ease-in-out;

    > .play-button {
      font-size: 5rem;
      color: var(--button-color);
      transition: all 0.25s ease-in-out;

      > .iconify {
        transition: all 0.25s ease-in-out;
        filter: var(--button-shadow);
      }
    }
  }
}
</style>
