<template lang="pug">
.vc-odd-pay-invoice-summary
  .top-wrapper
    .invoice-number
      span.title {{ `${modelNameLocaleText('odd_pay/invoice')}${attributeLocaleText('odd_pay/invoice', 'number')}` }}
      span {{ invoice.number }}
    .paid-at
      b-tooltip(
        :label="dateTimeLocaleText(invoice.completed_at)"
        position="is-left"
        type="is-dark"
        size="is-small"
      )
        span {{ timeAgoLocaleText(invoice.completed_at) }}
  .state-list
    .state-unit.invoice-state(:class="invoice.invoiceStateType")
      span.title {{ attributeLocaleText('odd_pay/invoice', 'invoice_state') }}
      span.content {{ aasmStateLocaleText('odd_pay/invoice', 'invoice_state', invoice.invoice_state) }}
    .state-unit.payment-state(:class="invoice.paymentStateType")
      span.title {{ attributeLocaleText('odd_pay/invoice', 'payment_state') }}
      span.content {{ aasmStateLocaleText('odd_pay/invoice', 'payment_state', invoice.payment_state) }}

  .section-wrapper.total
    .unit
    .unit(@click="showDetail = !showDetail")
      .icon.trigger(:class="{ closed: !showDetail }")
        i.fa.fa-chevron-up
    .unit
      .amount-wrapper
        .label {{ attributeLocaleText('odd_pay/invoice', 'subscribe_amount') }}
        span.price(data-currency="TWD")
          | {{ toMoney(invoice.amount, { isExchange: false }).format() }}
  .section-wrapper(v-if="showDetail")
    .section
      .section-title {{ attributeLocaleText('odd_pay/invoice', 'subscription_info') }}
      .section-body
        .is-flex
          .label {{ attributeLocaleText('odd_pay/invoice', 'period_type') }}
          .info
            span(v-if="subscriptionInfo.period_type === 'days'") {{ subscriptionInfo.period_point }}
            span {{ enumLocaleText('odd_pay/invoice', 'subscription_info.period_type', subscriptionInfo.period_type) }}
        .is-flex(v-if="invoice.expired_at")
          .label {{ attributeLocaleText('odd_pay/payment', 'valid_period') }}
          .info
            span {{ dateTimeLocaleText(invoice.paid_at, 'YYYY MMMDo') }} ~ {{ dateTimeLocaleText(invoice.expired_at, 'YYYY MMMDo') }}
        .is-flex
          .label {{ attributeLocaleText('odd_pay/invoice', 'email') }}
          .info {{ invoice.email }}
        .is-flex
          .label {{ attributeLocaleText('odd_pay/invoice', 'name') }}
          .info {{ invoice.name }}
        template(v-if="invoice.company_name || invoice.company_ein")
          .is-flex
            .label {{ attributeLocaleText('odd_pay/invoice', 'company_name') }}
            .info {{ invoice.company_name }}
          .is-flex
            .label {{ attributeLocaleText('odd_pay/invoice', 'company_ein') }}
            .info {{ invoice.company_ein }}
        .is-flex
          .label {{ attributeLocaleText('odd_pay/invoice', 'contact_phone') }}
          .info {{ invoice.contact_phone }}
        .is-flex
          .label {{ attributeLocaleText('odd_pay/invoice', 'note') }}
          .info(v-html="simpleFormat(invoice.note)")

    .section
      .section-title {{ attributeLocaleText('odd_pay/invoice', 'item_list') }}
      .section-body
        .list.items
          .labels
            .label.clear {{ attributeLocaleText('odd_pay/invoice/item', 'name') }}
            .label.clear.number {{ attributeLocaleText('odd_pay/invoice/item', 'quantity') }}
            .label.clear.price {{ attributeLocaleText('odd_pay/invoice/item', 'price') }}
          .info(v-for="item in invoice.items")
            span {{ item.name }}
            span.number {{ item.quantity }}
            span.price(data-currency="TWD")
              | {{ toMoney(item.price, { isExchange: false }).format() }}

    .section(v-if="invoice.payment_state !== 'checkout'")
      .section-title {{ attributeLocaleText('odd_pay/invoice', 'payment_list') }}
      .section-body
        .list.payments
          .labels
            .label.clear {{ attributeLocaleText('odd_pay/payment', 'paid_at') }}
            .label.clear.number {{ attributeLocaleText('odd_pay/payment', 'valid_period') }}
            .label.clear.price {{ attributeLocaleText('odd_pay/payment', 'amount') }}
          .info(v-for="payment in invoice.payments")
            span {{ dateTimeLocaleText(payment.paid_at) }}
            span.number {{ dateTimeLocaleText(payment.paid_at, 'YYYY MMMDo') }} ~ {{ dateTimeLocaleText(payment.expired_at, 'YYYY MMMDo') }}
            span.price(data-currency="TWD")
              | {{ toMoney(payment.amount, { isExchange: false }).format() }}

    option-buttons(:invoice="invoice")
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import OptionButtons from './option-buttons.vue'

const props = defineProps({
  invoice: {
    type: Object,
    required: true
  }
})

const showDetail = ref(false)

const payments = computed(() => props.invoice.payments)

const subscriptionInfo = computed(() => props.invoice.subscription_info)
</script>
