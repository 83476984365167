<template lang="pug">
.vc-dashboard-profile-get-phone-verification-code-button.button.is-small.is-odd(
  :class="{ 'is-loading': isLoading }"
  @click="getPhoneVerificationCodeSms"
)
  span {{ actionLocaleText('get_verification_code') }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    showVerifyPhoneButton: {
      type: Boolean,
      required: true
    },

    user: {
      type: Object,
      required: false
    },

    form: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    getPhoneVerificationCodeSms() {
      this.$store
        .dispatch('users/getPhoneVerificationCodeSms', this.form.sync())
        .then(() => {
          this.$emit('update:showVerifyPhoneButton', true)
        })
    }
  }
}
</script>
