<template lang="pug">
.vc-promotional-article-card(
  :class="{ 'is-age-restricted': !!this.promotionalArticle.is_age_restricted }"
)
  a.inner(:href="targetPath")
    //- .cover-wrapper
    img.cover(:src="coverSrc")
    .age-restricted-indicator
      .age-restricted-message
        i.fa.fa-ban
        .text R-18
    .content
      .article-title {{ promotionalArticle.title }}
</template>

<script>
const MOBILE_IMAGE_BREAK_POINT = 415

export default {
  // components: {},
  // mixins: [],
  props: {
    promotionalArticle: {
      type: Object,
      required: true
    },

    useBlogLink: {
      type: Boolean,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    coverSrc() {
      return this.isMobile
        ? this.promotionalArticle.mobile_cover.url ||
            this.promotionalArticle.cover.url
        : this.promotionalArticle.cover.url
    },

    targetPath() {
      if (this.useBlogLink)
        return `/blogs/${this.promotionalArticle.id}/${this.promotionalArticle.slug}`

      return `/promotional-articles/${this.promotionalArticle.id}`
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
