<template lang="pug">
.vc-custom-page-section-view
  div(
    :is="componentFor(customPageSection.category)"
    :custom-page-section="customPageSection"
  )
</template>

<script setup>
// import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'

import bannerSection from '@sharedComponents/custom_page_section/common/banner-section.vue'
import ModalLinkSection from '@sharedComponents/custom_page_section/common/modal-link-section.vue'
import promoterShareSection from '@sharedComponents/custom_page_section/common/promoter-share-section.vue'
import salesEventSection from '@sharedComponents/custom_page_section/common/sales-event-section.vue'
import productCategorySection from '@sharedComponents/custom_page_section/common/product-category-section.vue'
import customerServiceSection from '@sharedComponents/custom_page_section/common/custom-srevice-section.vue'
import partnerPlanSection from '@sharedComponents/custom_page_section/common/partner-plan-section.vue'
import promoterCampaignSection from '@sharedComponents/custom_page_section/common/promoter-campaign-section.vue'
import matchProductSection from '@sharedComponents/custom_page_section/common/match-product-section.vue'

const props = defineProps({
  customPageSection: { type: Object, requred: true }
})

const COMPONENT_MAP = {
  banner: bannerSection,
  promoter_share: promoterShareSection,
  sales_event: salesEventSection,
  partner_plan: partnerPlanSection,
  modal_link: ModalLinkSection,
  customer_service: customerServiceSection,
  product_category: productCategorySection,
  promoter_campaign: promoterCampaignSection,
  match_product: matchProductSection
}

const store = useStore()

const componentFor = (category) => {
  const component = COMPONENT_MAP[category]

  if (!component) return console.error('no match category', category)

  return component
}
</script>
