<template lang="pug">
.vc-dashboard-bonus-points-box
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .content-wrapper
    //- HEADER
    .content-header
      .icon
        i.fa.fa-product-hunt
      .text
      span {{ modelNameLocaleText('user/bonus_credit_transaction') }}

    //- BODY
    .content-body
      .bonus-credit-info(v-if="currentUserData.isDataLoaded()")
        .label {{ attributeLocaleText('user', 'bonus_credit') }}
        .bonus-credit {{ moneyFromAmount(currentUserData.bonus_credit).format() }}
      .transactions-header
        .order-number
          .label {{ attributeLocaleText('user/bonus_credit_transaction', 'order_number') }}
        .reason
          .label {{ attributeLocaleText('user/bonus_credit_transaction', 'reason') }}
        .credit-amount
          .label {{ attributeLocaleText('user/bonus_credit_transaction', 'credit_amount') }}
      .credit-transations
        a.credit-transaction(
          v-for="bonusCreditTransaction in bonusCreditTransactions"
          :key="bonusCreditTransaction.id"
          :href="orderUrl(bonusCreditTransaction)"
          @click.prevent="linkTo(bonusCreditTransaction)"
        )
          .order-number {{ bonusCreditTransaction.order_number }}
          .reason {{ bonusCreditTransaction.reason }}
          .credit-amount
            .icon
              i.fa.fa-product-hunt
            span {{ moneyFromAmount(bonusCreditTransaction.credit_amount).format() }}

  odd-pagination(
    :total="totalCount"
    :current.sync="currentPage"
    :per-page="pageSize"
    size="is-small"
    order="is-centered"
    :rounded="true"
    @change="onPageChange"
  )
</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'userBonusCreditTransactions',
      sortOrder: 'desc',
      sortField: 'created_at'
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    },

    bonusCreditTransactions() {
      return this.$store.getters['userBonusCreditTransactions/all']
    },

    hasBonusCreditTransactions() {
      return this.bonusCreditTransactions.length > 0
    }
  },
  created() {
    if (!this.currentUserData.isDataLoaded())
      this.$store.dispatch('users/find', this.currentUser.id)
    this.fetchingInitialData()
  },

  // mounted() {},
  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('users/fetchBonusCreditTransactions', {
        model: this.currentUserData,
        options: options
      })
    },

    orderUrl(bonusCreditTransaction) {
      if (!bonusCreditTransaction.order_token) return '#'

      return `/user/orders/${bonusCreditTransaction.order_token}`
    },

    linkTo(bonusCreditTransaction) {
      if (!bonusCreditTransaction.order_token) return

      Turbolinks.visit(`/user/orders/${bonusCreditTransaction.order_token}`)
    },

    updateQueryString(options) {}
  }
}
</script>
