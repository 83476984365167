export const SET_FLASH_MESSAGES = 'SET_FLASH_MESSAGES'
export const ADD_FLASH_MESSAGES = 'ADD_FLASH_MESSAGES'
export const CLEAR_FLASH_MESSAGES = 'CLEAR_FLASH_MESSAGES'
export const UPDATE_QUERY_STRING = 'UPDATE_QUERY_STRING'
export const TALLY_RETRY_COUNTER = 'TALLY_RETRY_COUNTER'
export const RESET_RETRY_COUNTER = 'RESET_RETRY_COUNTER'

export const TOGGLE_DEVICE = 'TOGGLE_DEVICE'
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'

export const SET_NAVBAR_STATE = 'SET_NAVBAR_STATE'
