import { defineComponent, onMounted, reactive, computed, ref } from 'vue'
import { useStore } from 'skid-composables'
import ShowContainer from '../../components/custom_page/show-container.vue'

export default defineComponent({
  components: {
    ShowContainer
  },

  setup(props, { emit }) {
    const store = useStore()
    const container = ref(null)
    const data = reactive({
      id: null
    })

    const fetchData = async () => {
      await store.dispatch('customPages/find', data.id)
    }

    const customPage = computed(() => {
      return store.getters['customPages/find'](data.id)
    })

    onMounted(async () => {
      data.id = container.value.dataset.customPage

      await fetchData()
    })

    return {
      container,
      data,
      customPage
    }
  }
})
