<template lang="pug">
.vc-product-slideshow
  .slideshow-wrapper
    swiper(
      :options="swiperOption"
      ref="swiperTop"
    )
      swiper-slide(
        v-for="(image, index) in images"
        :key="image.id"
      )
        template(v-if="index === 0")
          img.image(:src="image.image.url")
        template(v-else)
          img.image.swiper-lazy(:data-src="image.image.url")
          .swiper-lazy-preloader
      .swiper-pagination(slot="pagination")
  .thumbs-wrapper
    swiper.gallery-thumbs(
      :options="swiperOptionThumbs"
      ref="swiperThumbs"
    )
      swiper-slide(
        v-for="image in images"
        :key="image.id"
      )
        img.image(:src="image.image.thumb.url")
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],
  props: {
    images: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      swiperOption: {
        grabCursor: true,
        preloadImages: false,
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },

      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }
  },
  // computed: {},
  // created() {},
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper

      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },

  methods: {
    slideToImage(imageId) {
      const index = this.images.findIndex((image) => image.id === imageId)

      this.$refs.swiperTop.swiper.slideTo(index)
    }
  }
}
</script>
