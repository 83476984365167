<template lang="pug">
.vc-k-marketplace-container(
  :class="{ 'is-fixed-mode': navbarState.isFixedMode }"
)
  .tabs-wrapper(v-if="$slots.tabs")
    .inner
      slot(name="tabs")
  slot(name="body")
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'

const store = useStore()

const navbarState = computed(() => {
  const state = store.getters['navbarState']

  return {
    isFixedMode: state.isFixedMode
  }
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'

.vc-k-marketplace-container
  --nav-margin-bottom: 2rem
  --nav-tabs-height: 50px
  --mobile-nav-tabs-height: 80px
  --nav-tabs-top: 40px
  --mobile-nav-tabs-top: 110px

  margin-top: calc(var(--nav-margin-bottom) * -1)

  min-height: 300px
  .odd-container
    z-index: 2
    max-width: 1380px
    padding: 0 1rem 1rem

  .tabs-wrapper
    height: var(--mobile-nav-tabs-height)
    +utils.from(size.$ipad)
      height: var(--nav-tabs-height)
    .inner
      height: var(--mobile-nav-tabs-height)
      display: flex
      align-items: center
      +utils.from(size.$ipad)
        height: var(--nav-tabs-height)

  &.is-fixed-mode
    .tabs-wrapper
      .inner
        position: fixed
        left: 0
        width: 100%
        z-index: 3
        background: color.$white
        top: var(--mobile-nav-tabs-top)
        +utils.from(size.$ipad)
          top: var(--nav-tabs-top)
</style>
