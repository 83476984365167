<template lang="pug">
.vc-dashboard-level-info-detail
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )
  .info-wrapper
    .info-unit
      .label {{ attributeLocaleText('user', 'member_level') }}
      .info.price {{ user.member_level || '-' }}
    .info-unit
      .label {{ attributeLocaleText('user', 'life_time_value') }}
      .info.price(:data-currency="currentCurrency") {{ toMoney(user.life_time_value).format() }}
    .info-unit
      .label {{ attributeLocaleText('user', 'last_paid_at') }}
      .info {{ dateTimeLocaleText(user.last_paid_at) }}
    .info-unit
      .label {{ attributeLocaleText('user/level_info', 'started_at') }}
      .info {{ dateTimeLocaleText(user.level_info.started_at, 'YYYY MMMDo') }}
    .info-unit
      .label {{ attributeLocaleText('user/level_info', 'ended_at') }}
      .info {{ dateTimeLocaleText(user.level_info.ended_at, 'YYYY MMMDo') }}
    .info-unit
      .label {{ attributeLocaleText('user/level_info', 'renewed_at') }}
      .info {{ dateTimeLocaleText(user.level_info.renewed_at, 'YYYY MMMDo') }}

  .levels-wrapper
    b-collapse(
      v-for="(level, index) in levelsWithInformation"
      :key="index"
      animation="slide"
      :open="level.isShow"
      :class="{ 'displaying-level': level.isShow }"
    )
      template(
        slot="trigger"
        slot-scope="props"
      )
        a.panel-header(
          href="#"
          @click.prevent=""
          role="button"
          :class="{ 'is-active': props.open }"
        )
          span {{ level.title }}
          .icon
            i.fa(:class="`fa-angle-${props.open ? 'up' : 'down'}`")
      .panel-body
        .label {{ attributeLocaleText('member_level', 'merits_list') }}
        .list-content(
          v-for="(info, index) in level.meritsInfo"
          :key="`merit-${index}`"
        )
          .tag {{ info.description }}

        .label {{ attributeLocaleText('member_level', 'grant_criteria') }}
        .list-content(
          v-for="(info, index) in level.grantCriteriaInfo"
          :key="`grant-${index}`"
        )
          .tag {{ info.description }}
          b-progress(
            v-if="info.progressData"
            type="is-info"
            show-value
            :value="info.progressData.value"
            :max="info.progressData.max"
          ) {{ info.progressData.message }}

        .label {{ attributeLocaleText('member_level', 'renew_criteria') }}
        .list-content(
          v-for="(info, index) in level.renewCriteriaInfo"
          :key="`renew-${index}`"
        )
          .tag {{ info.description }}
          b-progress(
            v-if="info.progressData"
            type="is-info"
            show-value
            :value="info.progressData.value"
            :max="info.progressData.max"
          ) {{ info.progressData.message }}
</template>

<script>
import MemberLevelService from '@services/member_level_service.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    user: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      service: null
    }
  },

  computed: {
    isLoading() {
      return (
        this.$store.getters['memberLevels/isLoading'] ||
        this.$store.getters['users/isLoading']
      )
    },

    memberLevels() {
      return this.$store.getters['memberLevels/all']
    },

    levelsWithInformation() {
      return this.service?.levelsWithInformation || []
    }
  },
  // created() {},
  async mounted() {
    await this._ensureMemberLevelsLoaded()

    this.service = new MemberLevelService(this, this.user, this.memberLevels)
    await this.service.initialize()

    this._scrollToShow()
  },

  methods: {
    _ensureMemberLevelsLoaded() {
      if (this.memberLevels.length > 0) return

      return this.$store.dispatch('memberLevels/all', { sort: '-id' })
    },

    _scrollToShow() {
      this.$nextTick(() => {
        this.$el.parentElement.scroll({
          top: this.$el.querySelector('.displaying-level').offsetTop,
          behavior: 'smooth'
        })
      })
    }
  }
}
</script>
