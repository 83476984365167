import backendPaginateFilterSortAndSearchableMixin from '@sharedMixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OddPagination from '@applicationComponents/common/odd-pagination.vue'
import InvoiceSummary from '@/odd_pay/js/components/invoice/summary.vue'

export default {
  name: 'subscription-list-container',

  components: {
    InvoiceSummary,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'oddPayInvoices',
      currentPage: 1,
      pageSize: 12,
      sortOrder: 'desc',
      sortField: 'updated_at'
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    oddPayInvoices() {
      return this.$store.getters['oddPayInvoices/all']
    }
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
  },

  methods: {
    fetchData(options) {
      return this.$store.dispatch('users/fetchOddPayInvoices', options)
    }
  }
}
