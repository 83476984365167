<template lang="pug">
.vc-order-cart-item-list
  .order-items-container
    .list-header
      .label-part.main
        .label
          .icon
            i.fa.fa-list
          span {{ copyLocaleText('order_info') }}
      .label-part.info.unit-price
        .label
          span {{ attributeLocaleText('order/item', 'price') }}
      .label-part.info.quantity
        .label
          span {{ copyLocaleText('purchase_quantity') }}
      .label-part.info.price
        .label
          span {{ copyLocaleText('price') }}

    .items-group-by-shipping-method(v-for="shipmentForm in shipmentForms")
      .header
        .name
          | {{ partnerFor(shipmentForm).name }}

        cart-item-shipping-method-selector(
          :partner-id="shipmentForm.partner_id"
          :shipping-method-id.sync="shipmentForm.shipping_method_id"
          :preferred-shipping-date.sync="shipmentForm.preferred_shipping_date"
          :selectableShippingMethods="selectableShippingMethodsFor(shipmentForm)"
          :mode="mode"
        )

      cart-item(
        v-for="(cartItem, index) in cartItemsFor(shipmentForm, 'normal')"
        :index="index"
        :cart-item="cartItem"
        :cart-service="cartService"
        :is-confirmed="isConfirmed"
      )

      .list-header(v-if="cartItemsFor(shipmentForm, 'gift').length > 0")
        .label-part.main.gift
          .label
            .icon
              i.fa.fa-gift
            span {{ copyLocaleText('gift_info') }}

      cart-item(
        v-for="(giftItem, index) in cartItemsFor(shipmentForm, 'gift')"
        :key="giftItem.id"
        :index="index"
        :cart-item="giftItem"
        :cart-service="cartService"
        :isConfirmed="true"
        @cart-changed="cartChangedHandler"
      )
</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItem from './cart-item.vue'
import CartItemShippingMethodSelector from './cart-item-shipping-method-selector.vue'

export default {
  components: {
    CartItem,
    CartItemShippingMethodSelector
  },

  mixins: [checkoutFlowMixin],

  props: {
    currentStep: {
      required: false
    },

    giftItems: {
      type: Array,
      required: true
    },

    shipmentForms: {
      type: Array,
      required: true
    },

    mode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      shoppingCartShippingService: null
    }
  },

  computed: {
    isConfirmed() {
      if (this.currentStep && this.currentStep === 1) return false
      return ['confirmed', 'completed', 'canceled'].includes(
        this.order.order_state
      )
    }
  },
  // created() {},
  // mounted() {},

  // watch: {},

  methods: {
    cartItemsFor(shipmentForm, useType) {
      return shipmentForm.items
        .map((shipmentItem) => {
          const cartItem = this.cartItemFor(shipmentItem.item_id)

          if (cartItem.use_type != useType) return

          return cartItem
        })
        .filter(Boolean)
    },

    cartItemFor(id) {
      return this.$store.getters['orderItems/find'](id)
    },

    partnerFor(shipmentForm) {
      return this.$store.getters['partners/find'](shipmentForm.partner_id)
    },

    selectableShippingMethodsFor(shipmentForm) {
      return shipmentForm.selectable_shipping_method_ids.map((id) => {
        return this.$store.getters['shippingMethods/find'](id)
      })
    },

    cartChangedHandler() {
      console.log('cartChangedHandler')
    }
  }
}
</script>
