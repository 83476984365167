<script setup lang="ts">
import { Icon } from '@iconify/vue2'

const socialMediaList = [
  {
    name: 'Facebook',
    icon: 'bi:facebook',
    link: '#'
  },
  {
    name: 'Instagram',
    icon: 'bi:instagram',
    link: '#'
  },
  {
    name: 'Line@',
    icon: 'fa6-brands:line',
    link: '#'
  }
]
</script>

<template>
  <div class="vc-site-footer-brand-info">
    <div class="logo-wrapper">
      <img
        class="logo"
        src="@application/images/footer-kolnet-logo.svg"
        alt="KOLNET logo" />
    </div>
    <div class="slogan-wrapper">
      <div class="slogan-wrapper__content --zh">
        <span>共創精彩</span>
        <span>讓靈感點亮世界</span>
      </div>
      <div class="slogan-wrapper__content --en">
        <span>Unite with Brands</span>
        <span>Ignite Your Creativity!</span>
      </div>
    </div>

    <div class="social-media-info">
      <ul>
        <li
          v-for="(socialMedia, index) in socialMediaList"
          :key="index"
          class="social-media-info__item">
          <a
            :href="socialMedia.link"
            :title="socialMedia.name"
            class="social-media-info__link">
            <icon :icon="socialMedia.icon" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/media.sass'

.vc-site-footer-brand-info
  display: flex
  flex-direction: column
  align-items: center
  width: 60%
  padding-block: 1.5rem
  margin-inline: auto
  line-height: auto
  font-weight: font.$fw-bold

  +media.tablet-n-above
    align-items: self-start
    width: unset

  .logo-wrapper
    +media.tablet-n-above
      > .logo
        max-width: 135px

  .slogan-wrapper
    padding-block: 1rem
    text-align: left
    scale: 0.88

    span
      display: block

    +media.tablet-n-above
      scale: 1

  .slogan-wrapper__content
    &.--zh
      font-size: font.$fz-4
      color: color.$c-white

    &.--en
      font-size: font.$fz-6
      color: color.$c-mine-shaft

  .social-media-info
    font-size: font.$fz-4

    > ul
      display: flex
      gap: 1.5rem
      justify-content: center

    +media.tablet-n-above
      > ul
        gap: 1rem

  .social-media-info__item
    > .social-media-info__link
      color: color.$c-ci

    &:hover
      > .social-media-info__link
        color: color.$c-deep-sky-blue-darken
</style>
