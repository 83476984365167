<template lang="pug">
.vc-order-bonus-deducttion-checker(v-if="isRewardsProgramEnabled")
  b-field.bonus-label
    template#lable
      | {{ attributeLocaleText('order', 'bonus_deductible') }}
      b-tooltip(
        :label="bonusDeductionTip"
        type="is-odd"
      )
        b-icon(
          size="is-small"
          icon="question-circle-o"
        )

  .used-bonus(v-if="bonusAdjustment")
    .name
      span
        | {{ copyLocaleText('reward_program.deduction_amount') }}
      .price(data-currency="TWD")
        | {{ toMoney(bonusAdjustment.amount, { isExchange: false }).format() }}
    .delete-button(@click="removeBonusDeduction")
      .icon
        i.fa.fa-close

  template(v-else)
    b-field.bonus-field(
      :label="copyLocaleText('reward_program.deduct_amount_by_credit_balance', { credits: currentUserData.bonus_credit, price: bonusDeductionAmount(currentUserData.bonus_credit) })"
      :type="errors.errorClassAt('bonus_credits')"
      :message="errors.get('bonus_credits')"
    )
      b-field(
        v-if="rewardsProgramConfig.current_bonus_usage === 'user_defined'"
        custom-class="bonus-field"
      )
        b-input(
          type="number"
          icon="usd"
          v-model="usedBonusDeductionAmount"
          min="0"
          max="bonusAmountDeductionLimit"
          step="1"
          :disabled="!hasBonusCredit || bonusAmountDeductionLimit === 0"
          expanded
          @input="usedbonusDeductCreditHandler"
        )
        .control
          a.button.is-small.is-info(
            @click="useBonus"
            :disabled="!usedbonusDeductCredit"
          )
            span
              | {{ actionLocaleText('use_bonus_credits', { x: usedBonusCredits }) }}
      b-checkbox.bonus-checker(
        v-if="rewardsProgramConfig.current_bonus_usage === 'use_all'"
        type="is-odd"
        :disabled="!hasBonusCredit || bonusAmountForDeduction === 0"
        @input="useBonusBySwitch($event)"
      )
        span
          | {{ actionLocaleText('use_bonus_credits', { x: usedBonusCredits }) }}
        .deduction-amount
          span
            | {{ copyLocaleText('reward_program.deduction_amount') }}
          span.price(data-currency="TWD")
            | {{ moneyFromAmount(bonusAmountForDeduction).format() }}

  .usage-limit-tip
    span
      | {{ attributeLocaleText('site_config', 'bonus_usage_limit') }}
    .price(data-currency="TWD")
      | {{ moneyFromAmount(bonusAmountDeductionLimit).format() }}
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useCurrentUser, useI18n, useMoney, useStore } from 'skid-composables'
import debounce from 'lodash.debounce'

// 使用 defineProps 來定義 properties
const props = defineProps({
  order: {
    type: Object,
    required: true
  }
})

const usedbonusDeductCredit = ref(0)
const usedBonusDeductionAmount = ref(0)

const store = useStore()
const { messageLocaleText, copyLocaleText, attributeLocaleText } = useI18n()
const { moneyFromAmount } = useMoney()
const { currentUser, isUserSignedIn } = useCurrentUser()

/**
 * @returns {Object}
 */
const errors = computed(() => {
  return store.getters['users/errors']
})
/**
 * @returns {Object}
 */
const rewardsProgramConfig = computed(() => {
  return store.getters['siteConfigs/allConfigs'].rewards_program
})
/**
 * @returns {Object}
 */
const currentUserData = computed(() => {
  return store.getters['users/find'](currentUser.value.id)
})

/**
 * @return {Boolean}
 */
const hasBonusCredit = computed(() => {
  return currentUserData.value.bonus_credit > 0
})

/**
 * 是否可以使用紅利折抵
 * @returns {boolean}
 */
const isRewardsProgramEnabled = computed(() => {
  return isUserSignedIn && rewardsProgramConfig.value?.enabled
})

/**
 * @returns {number}
 */
const usedBonusCredits = computed(() => {
  if (rewardsProgramConfig.value.current_bonus_usage === 'use_all') {
    if (
      bonusDeductionAmount(currentUserData.value.bonus_credit) >=
      bonusAmountDeductionLimit.value
    )
      return bonusDeductCredit(bonusAmountDeductionLimit.value)

    return currentUserData.value.bonus_credit
  } else {
    return Number(usedbonusDeductCredit.value)
  }
})

/**
 * @return {String}
 */
const bonusDeductionTip = computed(() => {
  return copyLocaleText('reward_program.deduction_every_1_dollar', {
    credits: bonusDeductCredit(1)
  })
})

/**
 * @returns {Object}
 */
const bonusAdjustment = computed(() => {
  return store.getters['orders/allAdjustments'].find(
    (adjustment) =>
      adjustment.title ===
      copyLocaleText('reward_program.bonus_credit_deduction')
  )
})

/**
 * @returns {Array}
 */
const bonusUsageLimitList = computed(() => {
  const result = [parseInt(props.order.shopping_deduction_amount)]

  if (parseInt(rewardsProgramConfig.value.bonus_usage_limit) !== 0)
    result.push(parseInt(rewardsProgramConfig.value.bonus_usage_limit))
  if (parseInt(rewardsProgramConfig.value.bonus_usage_rate_limit) !== 0)
    result.push(
      Math.round(
        parseInt(props.order.shopping_deduction_amount) *
          parseInt(rewardsProgramConfig.value.bonus_usage_rate_limit) *
          0.01
      )
    )

  return result
})

/**
 * @returns {number}
 */
const bonusAmountForDeduction = computed(() => {
  const result = bonusDeductionAmount(usedBonusCredits.value)

  if (result > bonusAmountDeductionLimit.value) {
    return bonusAmountDeductionLimit.value
  } else {
    return result
  }
})
/**
 * @return {number}
 */
const bonusAmountDeductionLimit = computed(() => {
  return Math.min(...bonusUsageLimitList.value)
})

onMounted(async () => {
  if (!currentUserData.value.isDataLoaded())
    await store.dispatch('users/find', currentUser.value.id)
  if (isUserSignedIn) await store.dispatch('siteConfigs/fetchRewardsProgram')
})

/**
 * @returns {Promise<void>}
 */
const useBonus = () => {
  store.dispatch('users/useBonus', usedBonusCredits.value)
}

/**
 * @returns {Promise<void>}
 */
const useBonusBySwitch = (event) => {
  if (event === true) {
    useBonus()
  } else {
    removeBonusDeduction()
  }
}

/**
 * @returns {Promise<void>}
 */
const removeBonusDeduction = () => {
  store.dispatch('users/removeBonusDeduction').then((_) => {
    store.dispatch('addFlashMessage', [
      'notice',
      messageLocaleText('resource_deleted_successfully', {
        resource: attributeLocaleText('order', 'bonus_deductible')
      })
    ])
  })
}

/**
 * @returns {void}
 */
const usedbonusDeductCreditHandler = debounce(() => {
  errors.value.clear('bonus_credits')

  updateUsedbonusDeductCredit()

  if (usedbonusDeductCredit.value >= currentUserData.value.bonus_credit) {
    usedbonusDeductCredit.value = currentUserData.value.bonus_credit
  }
  if (usedBonusDeductionAmount.value >= bonusAmountForDeduction.value) {
    usedBonusDeductionAmount.value = bonusAmountForDeduction.value
    updateUsedbonusDeductCredit()
  }
}, 500)

/**
 * @returns {<void>}
 */
const updateUsedbonusDeductCredit = () => {
  usedbonusDeductCredit.value = bonusDeductCredit(
    usedBonusDeductionAmount.value
  )
}

/**
 * @returns {number}
 */
const bonusDeductCredit = (deductionAmount) => {
  return Math.round(
    deductionAmount / (rewardsProgramConfig.value.bonus_exchange_rate * 0.01)
  )
}

/**
 * @returns {number}
 */
const bonusDeductionAmount = (bonusCredits) => {
  return Math.floor(
    bonusCredits *
      parseInt(rewardsProgramConfig.value.bonus_exchange_rate) *
      0.01
  )
}
</script>
