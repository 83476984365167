import FormBase from 'odd-form_object'
import useShipmentsService from './cart_confirmation_form/shipments_service.js'

export default class PlaceOrderForm extends FormBase {
  constructor(model) {
    super(model)
  }

  generateShipmentsFromCartItemDataArray({ cartItemDataArray, oriShipments }) {
    const shipmentService = useShipmentsService()

    // 表單的 shipment 依照運送方式商品的運送方式做調整
    this.shipments = shipmentService.createShipmentsFromCartItems({
      cartItemDataArray: cartItemDataArray
    })

    // 回填原始設定
    this.shipments.forEach((shipment) => {
      const oriShipment = oriShipments.find(
        (ori) =>
          shipment.partner_id === ori.partner_id &&
          shipment.selectable_shipping_method_ids.includes(
            parseInt(ori.shipping_method_id)
          )
        )

      if (oriShipment) {
        shipment.shipping_method_id = oriShipment.shipping_method_id
        shipment.preferred_shipping_date = oriShipment.preferred_shipping_date
      }
    })
  }
}
