<template lang="pug">
.vc-order-cart-item-shipping-selector
  template(v-if="mode === 'edit'")
    .preferred-shipping-date-wrapper(
      v-if="selectablePreferredShippingDates.length > 0"
      :class="{ error: errors.get('shipments') }"
    )
      b-dropdown(
        :value="preferredShippingDate"
        @change="selectPreferredShippingDate"
      )
        .button.is-default(slot="trigger")
          span(v-if="preferredShippingDate")
            | {{ `預計出貨時間: ${moment(preferredShippingDate).format('MM/DD (dd)')}` }}
          span(v-else) 請選擇時段
          .icon
            i.fa.fa-caret-down

        b-dropdown-item(
          v-for="(config, index) in selectablePreferredShippingDates"
          :key="index"
          :value="config.shipping_date"
        ) {{ `預計出貨時間: ${moment(config.shipping_date).format('MM/DD (dd)')}` }}

      //- the message will show in address-info-unit.vue fro show mode
    .preferred-shipping-method-wrapper
      b-dropdown(
        :value="shippingMethodId"
        @change="changeShipingMethodId"
      )
        .button.is-default(slot="trigger")
          span {{ shippingMethod.name }}
          .icon
            i.fa.fa-caret-down
        b-dropdown-item(
          v-for="shippingMethod in selectableShippingMethods"
          :value="shippingMethod.id"
          :key="shippingMethod.id"
        ) {{ shippingMethod.name }}

  template(v-if="mode === 'show'")
    .selected-options
      .preferred-shipping-date(v-if="preferredShippingDate")
        span {{ `預計出貨日: ${moment(preferredShippingDate).format('MM/DD (dd)')}` }}
      .shipping-method
        span {{ shippingMethod.name }}
</template>

<script>
import { defineComponent, onMounted, computed, watch } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'

export default defineComponent({
  props: {
    partnerId: { type: [String, Number], required: true },
    shippingMethodId: { type: [String, Number], required: true },
    preferredShippingDate: { type: [String] },
    selectableShippingMethods: { type: Array, required: true },
    mode: { type: String, required: true }
  },

  setup(props, { emit }) {
    const store = useStore()

    const shippingMethod = computed(() => {
      return store.getters['shippingMethods/find'](props.shippingMethodId)
    })

    const partner = computed(() => {
      return store.getters['partners/find'](props.partnerId)
    })

    const selectedPartnerShippingMethodShip = computed(() => {
      return store.getters['partnerShippingMethodShips/findBy']({
        partner_id: parseInt(partner.value.id),
        shipping_method_id: parseInt(shippingMethod.value.id)
      })
    })

    const selectablePreferredShippingDates = computed(() => {
      return selectedPartnerShippingMethodShip.value
        .selectable_preferred_shipping_dates
    })

    const changeShipingMethodId = (value) => {
      emit('update:shipping-method-id', value)
    }

    const selectPreferredShippingDate = (value) => {
      errors.value.clear('shipments')

      emit('update:preferred-shipping-date', value)
    }

    const setDefaultPreferredShippingDate = () => {
      if (props.mode === 'show') return

      if (selectablePreferredShippingDates.value.length === 0) {
        emit('update:preferred-shipping-date', null)
      } else {
        emit(
          'update:preferred-shipping-date',
          selectablePreferredShippingDates.value[0].shipping_date
        )
      }
    }

    const errors = computed(() => {
      return store.getters['users/errors']
    })

    watch(selectedPartnerShippingMethodShip, () => {
      setDefaultPreferredShippingDate()
    })

    onMounted(() => {
      setDefaultPreferredShippingDate()
    })

    return {
      partner,
      shippingMethod,
      changeShipingMethodId,

      selectablePreferredShippingDates,
      selectedPartnerShippingMethodShip,
      selectPreferredShippingDate,
      moment,
      errors
    }
  }
})
</script>
