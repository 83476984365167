<template lang="pug">
.vc-common-currency-config-form(v-if="currencyRatesData")
  .form-header
    .title 參考匯率設定
  .form-body
    b-select.is-extended(
      v-if="currencyOptions.length > 2"
      v-model="selectedCurrency"
      placeholder="請選擇匯率"
      expanded
    )
      option(
        v-for="(key, index) in currencyOptions"
        :key="index"
      )
        | {{ key }}
    .radio-wrapper(v-else)
      b-radio(
        v-for="(key, index) in currencyOptions"
        :key="index"
        v-model="selectedCurrency"
        type="is-odd"
        :native-value="key"
      )
        | {{ key }}

    .currency-rates(:class="{ display: selectedCurrency }")
      span.label 目前匯率
      span.number 1
      span.unit {{ currencyRatesData['currency'] }}
      span.seperator
      span.number {{ displaycurrentCurrencyRate }}
      span.unit {{ selectedCurrency }}
      //- .tag {{ updateTime }}
  .form-footer.bottons
    button.button(@click="$parent.close()")
      | {{ actionLocaleText('close') }}
    button.button.is-odd(
      @click="confirm"
      :disabled="!currentCurrencyRate"
    )
      | {{ actionLocaleText('confirm') }}
</template>

<script>
import CurrencyReferenceService from '@services/currency_reference_service.js'
import { CURRENCY_REFERENCE_CONFIG_KEY } from '@services/currency_reference_service.js'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      service: null,
      currencyRatesData: null,
      selectedCurrency: null
    }
  },

  computed: {
    displaycurrentCurrencyRate() {
      return parseFloat(this.currentCurrencyRate.toFixed(3))
    },

    currencyOptions() {
      return CurrencyReferenceService.selectableCurrencies
    },

    currentCurrencyRate() {
      return Number.parseFloat(
        this.currencyRatesData.rates[this.selectedCurrency]
      )
    },

    updateTime() {
      return this.currencyRatesData.updateTime
    }
  },
  // created() {},
  async mounted() {
    await this.prepareData()
  },

  methods: {
    confirm() {
      this.service.updateCurrencyReferenceConfig(
        this.selectedCurrency,
        this.currentCurrencyRate,
        this.updateTime
      )
      this.$store.dispatch('addFlashMessage', [
        'success',
        `參考匯率設定為 ${this.selectedCurrency}`
      ])
      this.$parent.close()
    },

    async prepareData() {
      this.service = new CurrencyReferenceService(this.$vlf, this.$store)

      this.currencyRatesData = await this.service.fetchCurrencyData()

      if (!this.currencyRatesData || this.currencyOptions.length < 1) {
        this.$vlf.setItem(CURRENCY_REFERENCE_CONFIG_KEY, {})
        this.$store.dispatch('addFlashMessage', ['notice', '目前無可用匯率'])
        this.$parent.close()
      } else {
        this.selectedCurrency = this.currencyReferenceConfig.selectedCurrency
      }
    }
  }
}
</script>
