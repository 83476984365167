<template lang="pug">
.vc-layout-navbar-mobile-trigger-button(
  :class="{ active: props.active }"
  @click="clickHandler"
)
  .vc-layout-navbar-mobile-trigger-button__lines
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  active: { type: Boolean }
})

const clickHandler = () => {
  emit('update:active', !props.active)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'

.vc-layout-navbar-mobile-trigger-button
  --button-size: 44px
  --button-line-height: 3px

  display: grid
  place-items: center
  width: var(--button-size)
  aspect-ratio: 1
  padding: 0.8rem
  cursor: pointer
  translate: 0 -0.25rem

  > .vc-layout-navbar-mobile-trigger-button__lines
    +utils.has-transition

    position: relative
    width: 100%
    height: var(--button-line-height)
    background-color: var(--border-color)
    border-radius: var(--button-line-height)

    &::before,
    &::after
      +utils.has-transition

      position: absolute
      width: 100%
      height: 100%
      content: ""
      background-color: var(--border-color)
      border-radius: var(--button-line-height)

    &::before
      top: calc(var(--button-line-height) * 2)

    &::after
      top: calc(var(--button-line-height) * -2)

  &.active
    > .vc-site-navbar-menu-trigger__lines
      transform: rotate(45deg)

      &::before,
      &::after
        background-color: var(--border-color)

      &::before
        top: 0
        transform: rotate(90deg)

      &::after
        opacity: 0

  +utils.from(size.$ipad)
    display: none
</style>
