<template lang="pug">
.vc-dashboard-user-info-card
  .user-card
    .info-wrapper(v-if="currentUserData.isDataLoaded()")
      .avatar
        img(src="https://via.placeholder.com/100x100")
      .name-and-level
        .name
          span {{ currentUserData.name || ' - ' }}
          level-info-button(:user="currentUserData")
        .email
          span {{ currentUserData.email }}
    .account-option-wrapper
      a.option.account-settings(href="/user/settings")
        .icon
          i.fa.fa-gear
        span {{ actionLocaleText('account_settings') }}
</template>

<script>
import LevelInfoButton from './level-info-button.vue'

export default {
  components: {
    LevelInfoButton
  },
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    }
  },

  created() {
    if (!this.currentUserData.isDataLoaded())
      this.$store.dispatch('users/find', this.currentUser.id)
  }
  // mounted() {},
  // methods: {}
}
</script>
