<template lang="pug">
.vc-common-address-unit(:class="unitClasses")
  .index-indicator
    span.index {{ index + 1 }}
  a.address-info(
    href="#"
    @click.prevent="addressSelected"
  )
    .info-section.recipient
      span.label {{ attributeLocaleText('user/common_address', 'name') }}
      span.data {{ address.recipient }}
    .info-section.phone
      span.label {{ attributeLocaleText('user/common_address', 'phone') }}
      span.data {{ address.fullPhone() }}
    .info-section.company(
      v-for="(info, index) in companyOrInvoiceCarrierInfo"
      :key="index"
    )
      span.label {{ info.label }}
      span.data {{ info.value }}
    .info-section.address.cvs-info(v-if="address.isCvsStoreInfo()")
      span.label {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}：
        | {{ cvsStoreInfo.cvs_store_name }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_id') }}：
        | {{ cvsStoreInfo.cvs_store_id }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}：
        | {{ cvsStoreInfo.cvs_telephone }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_address') }}：
        | {{ cvsStoreInfo.cvs_address }}
    .info-section.address.cvs-info(v-if="address.isStoreInfo()")
      span.label {{ enumLocaleText('shipping_method', 'sub_logistic_type', address.address.extra_data) }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_store_name') }}：
        | {{ storeInfo.name }}
      //- span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_telephone') }}：
        | {{ storeInfo.cvs_telephone }}
      span.data
        | {{ attributeLocaleText('user/common_address', 'cvs_address') }}：
        | {{ address.storeFullAddress() }}
    .info-section.address(
      v-if="address.isNormalAddress() || address.isOverseaLogistic()"
    )
      span.label {{ attributeLocaleText('user/common_address', 'address') }}
      span.data {{ address.fullAddress() }}
  .button.is-medium.delete(@click="destroyCommonAddress(address)")
</template>

<script>
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'

export default {
  // components: {},
  // mixins: [],
  props: {
    address: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: false,
      default: () => {
        return 0
      }
    },

    selectedAddress: {
      type: [Object, Number],
      required: false,
      default: () => {
        return 0
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    isSelected() {
      return isEqual(this.address, this.selectedAddress)
    },

    unitClasses() {
      return {
        selected: this.isSelected
      }
    },

    cvsStoreInfo() {
      return this.address.address.cvs_store_info
    },

    storeInfo() {
      return this.address.address.store_info
    },

    invoiceTypes() {
      return this.$store.getters['siteConfigs/invoiceTypes']
    },

    invoiceServiceEnabled() {
      return this.$store.getters['siteConfigs/invoiceServiceEnabled']
    },

    companyOrInvoiceCarrierInfo() {
      if (this.address.address.extra_data !== 'billing') return []
      let hasCompanyInfo = this.address.hasCompanyInfo()
      let invoiceType = this.address.address.invoice_type
      if (invoiceType && this.invoiceServiceEnabled) {
        let invoiceTypeInfo = this.invoiceTypes.find(
          (type) => type.key === invoiceType
        )
        return [
          {
            label: invoiceTypeInfo?.name,
            value:
              invoiceTypeInfo?.key === 'love_code'
                ? this.address.loveCodeAndName
                : this.address.carrierNumber
          }
        ]
      } else if (hasCompanyInfo) {
        return [
          {
            label: this.attributeLocaleText('user/common_address', 'company'),
            value: this.address.company
          },
          {
            label: this.attributeLocaleText('user/common_address', 'ein'),
            value: this.address.ein
          }
        ]
      } else {
        return []
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    addressSelected() {
      this.$emit('address-selected', cloneDeep(this.address))
    },

    destroyCommonAddress(address) {
      this.$store.dispatch('users/destroyCommonAddress', address.id)
    }
  }
}
</script>
