import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'skid-composables'
import ShowContainer from '../../components/marketplace_partner/show-container.vue'

export default defineComponent({
  components: {
    ShowContainer
  },

  data() {
    return {
      isDataInited: false,
      customPageId: null
    }
  },

  mounted() {
    this.customPageId = this.$refs.container.dataset.customPage

    this.isDataInited = true
  }
})
