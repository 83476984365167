<template lang="pug">
.vc-layout-navbar-items-tab(:class="{ 'is-fixed-mode': isFixedMode }")
  k-tabs(
    style-type="kolnet-rounded-v2"
    :value="currentTab"
    @input="inputHandler"
    :options="{ isFull: isFixedMode }"
  )
    b-tab-item(
      v-for="(item, index) in items"
      :key="index"
      :label="item.label"
      :icon-pack="item.iconPack"
      :icon="item.icon"
      :value="item.value"
    )
</template>

<script setup>
import { computed } from 'vue'
import KTabs from '@sharedComponents/common/k-tabs.vue'

const props = defineProps({
  currentTab: { type: String },
  items: { type: Array },
  isFixedMode: { type: Boolean }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  const item = props.items.find((i) => i.value === value)

  setTimeout(() => {
    Turbolinks.visit(item.links[0])
  }, 200)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'

.vc-layout-navbar-main-items-nav
  .k-tabs
    height: 38px
  &.is-fixed-mode
    .k-tabs .tabs
      width: 100%
    .k-tabs .tabs ul
      width: 100%
      li
        flex-grow: 1
      li a
        width: 100%
        border-top-left-radius: 0
        border-top-right-radius: 0
</style>
