import HotSection from '../../components/blog_category/hot-section.vue'
import BlogCard from '../../components/blog/card.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'

export default {
  name: 'blog-index-page-container',

  components: {
    BlogCard,
    OddPagination,
    HotSection
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'promotionalArticles',
      currentPage: 1,
      pageSize: 10,
      sortField: '-published_at',
      selectedCategoryId: undefined
    }
  },

  computed: {
    sectionTitle() {
      if (this.isCategorySelected) return this.selectedCategory.name
      if (Object.keys(this.searchOptions).length > 0)
        return this.copyLocaleText('search_result')
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    isCategorySelected() {
      return this.selectedCategory.id !== undefined
    },

    selectedCategory() {
      return this.$store.getters['categorySystems/findCategory'](
        this.selectedCategoryId
      )
    },

    allCategories() {
      return this.$store.getters['categorySystems/allCategories']()
    },

    blogs() {
      return this.$store.getters['promotionalArticles/all']
    },

    firstBlog() {
      return this.blogs[0]
    },

    sideBlogs() {
      return this.blogs.slice(1, 4)
    },

    bottomBlogs() {
      return this.blogs.slice(4, 10)
    }

    // additionalOptions() {
    //   return {
    //     category: this.selectedCategoryId,
    //   }
    // },
  },

  // created() {},

  mounted() {
    this.selectedCategoryId = this.$el.dataset.category
    this._ensureCategoriesLoaded()
  },

  methods: {
    // checkAdditionalOptionsFromUrl() {
    //   return {
    //     category: this.currentQueryString['category'] || this.selectedCategoryId,
    //   }
    // },

    // updateQueryOptionsForAdditionalOptions(options) {
    //   if (options.category) this.selectedCategoryId = parseInt(options.category)
    // },

    // additionalOptionsToQueryString(options) {
    //   let result = ''

    //   if (options.category) result += `&category=${options.category}`

    //   return result
    // },

    fetchData(options) {
      this.currentPage = options.pageNumber

      if (this.selectedCategoryId) {
        this._fetchCategoryBlogs(options)
      } else {
        this._fetchBlogs(options)
      }
    },

    _ensureCategoriesLoaded() {
      if (this.categories) return this.fetchingInitialData()

      this.$store
        .dispatch('categorySystems/fetchCategoriesByType', {
          type: 'promotional_article',
          sort: 'created_at'
        })
        .then(() => {
          this.fetchingInitialData()
        })
    },

    _fetchCategoryBlogs(options) {
      this.$store.dispatch('categorySystems/fetchCategoryPromotionalArticles', {
        model: this.selectedCategory,
        options: Object.assign(options, {
          sub_categories_included: true
        })
      })
    },

    _fetchBlogs(options) {
      this.$store.dispatch(
        'promotionalArticles/all',
        Object.assign(options, {
          usage: 'blog'
        })
      )
    }
  }
}
