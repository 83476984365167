<template lang="pug">
.vc-common-slideshow-container(v-if="hasBanner")
  .section-header.-white(v-if="hasTitle")
    .odd-container.has-padding
      h2.section-title
        .en {{ titleEn }}
        .zh {{ titleZh }}
  .slideshow-wrapper
    swiper(:options="swiperOption")
      swiper-slide(
        v-for="banner in banners"
        :key="banner.id"
      )
        a.link(
          :href="banner.url"
          target="_blank"
          ref="noopenner noreferrer"
          @click="sendPromoEvent(banner)"
        )
          img.image(
            :src="isMobile ? banner.mobile_image.url || banner.image.url : banner.image.url"
          )
      .swiper-pagination(slot="pagination")
  .decoration-stripe(v-if="hasDecoration")
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],

  props: {
    /**
     * 指定這個 slideshow 該顯示的內容
     * 當 useType 為 `product_categories` 時，會需要搭配 category property 的內容取得該分類的 banners
     */
    useType: {
      type: String,
      required: false,
      default: () => {
        return 'homepage'
      }
    },

    /**
     * 要顯示 category banners 的時候需給這個 property
     */
    category: {
      type: Object,
      required: false
    },

    hasDecoration: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },

    titleEn: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },

    titleZh: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    }
  },

  data() {
    return {
      swiperOption: {
        preventClicks: false,
        effect: 'fade',
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      initialUseType: undefined, // record useType property at mounted
      bannersCache: null
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_IMAGE_BREAK_POINT
      )
    },

    /**
     * @returns {Banner[]}
     */
    banners() {
      if (this.bannersCacheable)
        return (
          this.bannersCache ||
          this.$store.getters['banners/allByType'](this.useType)
        )

      return this.$store.getters['banners/allByType'](this.useType)
    },

    /**
     * banners should be cache if useType is `products` or `adults_only`.
     * because category changes will happen in high frequency.
     * @returns {boolean}
     */
    bannersCacheable() {
      return ['products', 'adults_only'].includes(this.useType)
    },

    hasBanner() {
      return this.banners.length > 0
    },

    hasTitle() {
      return this.titleEn && this.titleZh
    }
  },

  watch: {
    category: {
      /**
       * observe category change. if new value is `undefined`, update `useType` to `initialUseType`
       * @param {?ProductCategory} newValue
       */
      handler(newValue) {
        if (!newValue) this.$emit('update:useType', this.initialUseType)

        this.fetchBanners()
      }
    }
  },

  // created() {},

  mounted() {
    this.initialUseType = this.useType
    this.fetchBanners()
  },

  methods: {
    /**
     * @returns {Promise<void>}
     */
    async fetchBanners() {
      if (!!this.category) {
        await this.__fetchCategoryBanners()
      } else {
        await this.__fetchBannersByUseType()
      }
    },

    /**
     * fetch regular banners by `useType`. if multiple component instances in view, results will be merge into store.
     * call API only if bannersCache is null.
     * if `bannersCacheable`, the results of API call will been cached in `bannersCache`.
     *
     *  **`useType` will mutate when API called.**
     * @returns {Promise<void>}
     * @private
     */
    async __fetchBannersByUseType() {
      const options = { filter: this.useType, noReplace: true }

      if (!this.bannersCache) {
        // note that empty array is truthy in js
        await this.$store.dispatch('banners/allByUseType', options)
        this.$emit('update:useType', this.initialUseType)

        if (this.bannersCacheable) this.bannersCache = this.banners
      }
    },

    /**
     * fetch banners that belongs to `category`. results will replace store.
     *
     * **`useType` will mutate when results of API called not empty.**
     * @returns {Promise<void>}
     * @private
     */
    async __fetchCategoryBanners() {
      const options = {
        noReplace: false,
        filter: 'product_categories',
        search: {
          product_category_id_eq: this.category.id
        }
      }

      const result = await this.$store.dispatch('banners/allByUseType', options)
      if (result.data.data.length > 0)
        this.$emit('update:useType', 'product_categories')
    },

    sendPromoEvent(banner) {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addPromo',
          {
            id: banner.id,
            name: banner.alt_text,
            creative: `${window.location.protocol}//${window.location.host}${banner.image.url}`,
            position: `${banner.use_type} banner ${banner.position}`
          }
        ],
        ['ec:setAction', 'promo_click'],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Internal Promotions',
            eventAction: 'click',
            eventLabel: banner.alt_text
          }
        ]
      ])
    }
  }
}
</script>
