<template lang="pug">
.promoter-member-new-form-fields
  b-field(
    :type="errors.errorClassAt('email')"
    :message="errors.get('email')"
    custom-class="required"
  )
    b-input(
      type="text"
      size="is-large"
      :placeholder="attributeLocaleText('promoter_member', 'email')"
      v-model="form.email"
      data-behavior="promoter-email"
      name="promoter_member[email]"
      @input="errors.clear('email')"
    )

  b-field(
    :type="errors.errorClassAt('name')"
    :message="errors.get('name')"
    custom-class="required"
  )
    b-input(
      type="text"
      size="is-large"
      v-model="form.name"
      :placeholder="attributeLocaleText('promoter_member', 'name')"
      data-behavior="promoter_member-name"
      @input="errors.clear('name')"
    )

  b-field(
    :type="errors.errorClassAt('password')"
    :message="errors.get('password')"
    custom-class="required"
  )
    b-input(
      type="password"
      size="is-large"
      :placeholder="attributeLocaleText('promoter_member', 'password')"
      autocomplete="new-password"
      v-model="form.password"
      data-behavior="promoter-password"
      name="promoter_member[password]"
      @input="errors.clear('password')"
    )

  b-field(
    :type="errors.errorClassAt('password_confirmation')"
    :message="errors.get('password_confirmation')"
    custom-class="required"
  )
    b-input(
      type="password"
      size="is-large"
      :placeholder="attributeLocaleText('promoter_member', 'password_confirmation')"
      v-model="form.password_confirmation"
      data-behavior="promoter-password-confirmation"
      @input="errors.clear('password_confirmation')"
    )
</template>

<script>
import moment from 'moment'

export default {
  components: {},

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    }
  },

  watch: {},

  async mounted() {},

  methods: {}
}
</script>
