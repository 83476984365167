import * as types from './mutation-types'
import Impression from '../../resource_models/impression'

export const create = (
  { dispatch, commit },
  { impressionableType, impressionableId, uuid }
) => {
  commit(types.API_REQUEST_START, 'create')

  return new Promise((resolve, reject) => {
    Impression.create({
      impressionableType,
      impressionableId,
      uuid
    })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: create,
            ref: {
              dispatch,
              commit
            },
            params: Impression
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
