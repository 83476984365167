<template lang="pug">
.vc-layout-navbar-upper-user-option-trigger-wrapper
  a.user-options-trigger(:href="loginUrl")
    img.user-options-trigger__k-icon(:src="KIcon")
    span.text
      | 註冊 | 登入
  .inception-program
    img.image(:src="NvidiaInception")
</template>

<script setup>
import { computed } from 'vue'
import KIcon from '@application/images/navbar/k-icon.svg'
import NvidiaInception from '@application/images/navbar/nvdia-inception.png'

const props = defineProps({
  loginUrl: { type: String }
})

const inputHandler = (value) => {
  emit('input', value)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'

.vc-layout-navbar-upper-user-option-trigger-wrapper
  position: relative
  --fz-small: #{font.$small}
  --fz-normal: #{font.$normal}

  .user-options-trigger
    +utils.has-transition(.3, box-shadow)

    --trigger-bg-color: linear-gradient(to right, var(--border-color) 0%, var(--border-color-lighten) 100%)
    --trigger-border-color: hsl(0deg 0% 100% / 35%)

    position: relative
    z-index: 1
    display: flex
    place-items: center
    padding-block: 0.25rem
    padding-inline: 0.75rem
    padding-right: 0
    font-size: var(--fz-small)
    font-weight: font.$semibold
    color: var(--c-white)
    text-align: center
    cursor: pointer
    background-image: var(--trigger-bg-color)
    border: 3px solid var(--trigger-border-color)
    border-right: 0
    border-radius: 100px 0 0 100px
    box-shadow: none

    &:hover
      box-shadow: var(--shadow-2)

    > .user-options-trigger__k-icon
      display: none
      scale: 0.8
      translate: 0 -0.15rem

    > .text
      padding-inline: 0.5em

    +utils.from(size.$ipad)
      font-size: var(--fz-normal)

      > .user-options-trigger__k-icon
        display: inline-block

  .inception-program
    position: absolute
    top: 0
    right: 0
    z-index: 0
    display: none
    width: 90%
    opacity: 0
    animation: inception-logo-fade-in 3s ease-out forwards

    +utils.from(size.$ipad)
      display: block

@keyframes inception-logo-fade-in
  to
    top: calc(100% + 0.25rem)
    opacity: 0.8
</style>
