<template lang="pug">
button.vc-product-sort-button.button.tool(@click="showSortOptions")
  span.text {{ sortOptionText }}
  .icon
    i.fa(:class="sortOptionIcon")
</template>

<script>
import Product from '../../../../shared/resource_models/product.js'
import SortOptionsSelector from './sort-options-selector.vue'

export default {
  components: {
    SortOptionsSelector
  },
  // mixins: [],
  props: {
    sortField: {
      type: String,
      required: true,
      validator: (value) => {
        return Product.sortableFields.includes(value)
      }
    },

    sortOrder: {
      type: String,
      required: true,
      validator: (value) => {
        return ['asc', 'desc'].includes(value)
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    sortOptionText() {
      return this.actionLocaleText('sort_by', {
        name: this.enumLocaleText('product', 'sort_option', this.sortField)
      })
    },

    sortOptionIcon() {
      return {
        'fa-sort-amount-asc':
          this.sortField === 'master.sell_price_cents' &&
          this.sortOrder === 'asc',
        'fa-sort-amount-desc':
          this.sortField === 'master.sell_price_cents' &&
          this.sortOrder === 'desc',
        'fa-sort-alpha-asc':
          this.sortField === 'name' && this.sortOrder === 'asc',
        'fa-sort-alpha-desc':
          this.sortField === 'name' && this.sortOrder === 'desc',
        'fa-sort-numeric-asc':
          this.sortField === 'created_at' && this.sortOrder === 'asc',
        'fa-sort-numeric-desc':
          this.sortField === 'created_at' && this.sortOrder === 'desc'
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    showSortOptions() {
      this.$buefy.modal.open({
        parent: this,
        component: SortOptionsSelector,
        width: 380,
        props: {
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        events: {
          // 'field-updated': field => this.$emit('update:sortField', field),
          // 'order-updated': order => this.$emit('update:sortOrder', order),
          'sort-option-updated': (field, order) => {
            this.$emit('sort-option-updated', field, order)
          }
        }
      })
    }
  }
}
</script>
