<script lang="ts" setup>
import { ref } from 'vue'

const siteMapList = ref([
  {
    sectionTitle: '平台服務項目',
    items: [
      {
        text: '銷售熱門商品',
        link: '#'
      },
      {
        text: '品牌合作媒合配對',
        link: '#'
      },
      {
        text: '品牌主題精選活動',
        link: '#'
      },
      {
        text: '餐廳美食體驗',
        link: '#'
      },
      {
        text: '旅遊住宿體驗',
        link: '#'
      },
      {
        text: 'KShop KOL 專屬電商系統',
        link: '#'
      },
      {
        text: 'KOL Center 會員商務媒合中心',
        link: '#'
      },
      {
        text: 'KStar 星辰會員',
        link: '#'
      }
    ]
  },
  {
    sectionTitle: '官方行銷服務',
    items: [
      {
        text: '行銷企劃服務',
        link: '#'
      },
      {
        text: '品牌顧問服務',
        link: '#'
      },
      {
        text: '第三方行銷資源',
        link: '#'
      }
    ]
  },
  {
    sectionTitle: '聯繫我們',
    items: [
      {
        text: '平台使用問題',
        link: '#'
      },
      {
        text: '合作洽詢',
        link: '#'
      },
      {
        text: '異業結合',
        link: '#'
      },
      {
        text: '購物問題',
        link: '#'
      },
      {
        text: '其他問題詢問',
        link: '#'
      }
    ]
  }
])
</script>

<template>
  <div class="vc-site-footer-site-map">
    <section
      v-for="(section, index) in siteMapList"
      :key="index"
      class="site-section">
      <h3 class="site-section__title">{{ section.sectionTitle }}</h3>
      <ul>
        <li
          v-for="(item, index) in section.items"
          :key="index"
          class="site-section__item">
          <a :href="item.link" class="site-section__link">
            {{ item.text }}
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<style scoped lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/media.sass'

.vc-site-footer-site-map
  display: none

  +media.tablet-n-above
    display: grid
    grid-template-columns: repeat(3, max-content)
    gap: 3vw
    justify-content: end
    padding-left: 1rem

  +media.ipad-pro-n-above
    gap: 5vw

  +media.laptop-n-above
    gap: min(8vw, 10rem)

  .site-section
    padding-top: 4.375rem
    padding-bottom: 2rem

    > ul
      display: grid
      grid-template-rows: repeat(5, 1fr)
      grid-auto-flow: column
      gap: 0.5em 2em

  .site-section__title
    margin-bottom: 0.5em
    font-size: font.$fz-6

  .site-section__item
    +utils.has-transition-all

    width: max-content

    &:hover
      translate: -0.25em 0

  .site-section__link
    color: color.$c-white
    text-decoration: none
    font-weight: font.$fw-bold
</style>
