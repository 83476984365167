<template lang="pug">
.vc-slide-carousel-container
  b-carousel(:pause-info="false")
    b-carousel-item(
      v-for="(slide, index) in slides"
      :key="index"
    )
      a.link-wrapper(
        href="#"
        @click.prevent="slideClickHandler(slide)"
      )
        img.image(
          :src="slide[displayColumn].url"
          :alt="slide.config.alt_text"
        )
        .icon.play(v-if="slide.config.use_type === 'youtube'")
          i.fa.fa-play-circle-o
</template>

<script>
import youtubeIframeBox from './youtube-iframe-box.vue'

export default {
  // components: {},
  // mixins: [],
  props: {
    parent: {
      type: Object,
      required: true
    },

    displayColumn: {
      type: String,
      required: true
    },

    parentStoreModuleName: {
      type: String,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    slides() {
      return this.$store.getters['slides/all']
    }
  },
  // created() {},
  mounted() {
    this.$store
      .dispatch(`${this.parentStoreModuleName}/fetchSlides`, {
        model: this.parent,
        options: {
          sort: 'position',
          pageSize: 100
        }
      })
      .then((_) => {
        this.$emit('update:slides-ready', this.slides.length > 0)
      })
  },

  methods: {
    slideClickHandler(slide) {
      switch (slide.config.use_type) {
        case 'normal':
          let link = slide.config.link_url
          if (!link) return

          if (slide.config.target_blank) {
            window.open(link, '_blank')
          } else {
            Turbolinks.visit(link)
          }
          break
        case 'youtube':
          this.openYoutubeBox(slide)
          break
      }
    },

    openYoutubeBox(slide) {
      this.$buefy.modal.open({
        parent: this,
        component: youtubeIframeBox,
        props: {
          slide
        }
      })
    }
  }
}
</script>
