<template lang="pug">
.vc-common-background-slide
  .slide-column(v-for="index in slideColumnCount")
    .inner
      img.pic(
        v-for="index in 8"
        :src="pickRandomSlideImage()"
        @load="onImageLoad"
      )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useUnsplash } from 'skid-composables'

export default defineComponent({
  // components: {},
  // mixins: [],
  props: {
    slideColumnCount: {
      type: Number,
      required: false,
      default: 6
    }
  },

  setup(props) {
    const MAX_ID = 648
    const selectedIDs = []
    const pickAnID = () => {
      let id = Math.floor(Math.random() * MAX_ID) + 1

      while (selectedIDs.includes(id)) {
        id = Math.floor(Math.random() * MAX_ID) + 1
      }

      selectedIDs.push(id)
      return id
    }

    return {
      // methods
      pickRandomSlideImage() {
        return `https://pub-b9fe616a8af8498d8a50230585d16b41.r2.dev/kolnet-${pickAnID()}.jpg`
      },
      onImageLoad(event) {
        event.target.classList.add('-is-loaded')
      }
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
})
</script>
