<template lang="pug">
.vc-common-forms-tappay(style="background: #fff; padding: 20px")
  form.form-section
    .field
      label.label(for="name")
        | {{ attributeLocaleText('user/credit_card', 'cardholder_name') }}
      .control
        input.input(
          type="text"
          placeholder=""
          v-model="onsitePaymentData.cardholder_name"
        )
    .field
      label.label(for="email")
        | {{ attributeLocaleText('user/credit_card', 'cardholder_email') }}
      .control
        input.input(
          type="email"
          placeholder="Email"
          v-model="onsitePaymentData.cardholder_email"
        )
    .field
      label.label(for="phone_number")
        | {{ attributeLocaleText('user/credit_card', 'cardholder_phone') }}
      .control
        input.input(
          type="text"
          placeholder=""
          v-model="onsitePaymentData.cardholder_phone_number"
        )
    .field.card-number-group
      label.label(for="card-number")
        span#card_type
          | {{ attributeLocaleText('user/credit_card', 'card_no') }}
      .control
        .input.card-number
      .help.is-danger(v-if="cardNoError")
        | {{ messageLocaleText('user_credit_card_info.card_no.not_completely_filled') }}
    .field.expiration-date-group
      label.label(for="expiration-date")
        | {{ attributeLocaleText('user/credit_card', 'expiry_date') }}
      .control
        #tappay-expiration-date.input.expiration-date
      .help.is-danger(v-if="expiryError")
        | {{ messageLocaleText('user_credit_card_info.expiry_date.not_completely_filled') }}
    .field.cvc-group
      label.label
        | {{ attributeLocaleText('user/credit_card', 'ccv') }}
      .control
        .input.cvc
      .help.is-danger(v-if="ccvError")
        | {{ messageLocaleText('user_credit_card_info.ccv.not_completely_filled') }}
    .field
      b-checkbox(
        type="is-odd"
        v-model="onsitePaymentData.remember_card"
        v-if="showRememberCreditCard"
      ) {{ attributeLocaleText('user/credit_card', 'remember_card') }}
    .field
      b-checkbox(
        type="is-odd"
        v-model="onsitePaymentData.is_default"
        v-if="showRememberCreditCard && onsitePaymentData.remember_card"
      ) {{ attributeLocaleText('user/credit_card', 'set_default') }}
      p.help.is-danger
        | {{ errorMessage }}
    .cart-options
      .button.is-odd.back-to-store.next-step(
        @click.prevent="onClickSubmit"
        :disabled="!canGetPrime"
        :class="{ 'is-loading': isLoading }"
      ) {{ messageLocaleText('user_credit_card_info.confirm_to_pay') }}
</template>

<script>
import TappayMixin from '../mixins/tappay_mixin.js'
const CONFIG = {
  isUsedCcv: true
}
const CARD_FIELDS = {
  number: {
    element: '.card-number',
    placeholder: '**** **** **** ****'
  },

  expirationDate: {
    element: '#tappay-expiration-date',
    placeholder: 'MM / YY'
  },

  ccv: {
    element: '.cvc',
    placeholder: '末三碼'
  }
}
const CARD_STYLE = {
  input: {
    color: 'gray'
  },

  'input.ccv': {
    // 'font-size': '16px'
  },

  ':focus': {
    color: 'black'
  },

  '.valid': {
    color: 'green'
  },

  '.invalid': {
    color: 'red'
  },

  '@media screen and (max-width: 400px)': {
    input: {
      color: 'orange'
    }
  }
}

export default {
  // components: {},
  mixins: [TappayMixin],
  props: {
    onsitePaymentData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      errorMessage: '',
      cardNoError: false,
      expiryError: false,
      ccvError: false,
      canGetPrime: false
    }
  },

  computed: {
    showRememberCreditCard() {
      return this.isUserSignedIn
    },

    userCreditCards() {
      return this.$store.getters['userCreditCards/all']
    }
  },

  watch: {
    'onsitePaymentData.remember_card': {
      handler() {
        if (!this.onsitePaymentData.remember_card)
          this.onsitePaymentData.is_default = false
      }
    }
  },

  // created() {},

  mounted() {
    this._tryFetchUserCreditCards().then((_) =>
      this._setIsDefaultByUserCreditCards()
    )
    this.setupTappay().then((_) => {
      TPDirect.card.setup({ fields: CARD_FIELDS, styles: CARD_STYLE })
      TPDirect.card.onUpdate((update) => {
        this.canGetPrime = update.canGetPrime
        this.cardNoError = update.status.number === 2
        this.expiryError = update.status.expiry === 2
        this.ccvError = update.status.ccv === 2
      })
    })
  },

  methods: {
    onClickSubmit() {
      this._getPrime()
        .then((response) => {
          this.onsitePaymentData.prime = response.card.prime
          this.isLoading = true
          this.$emit('form-submitted', {
            afterSubmitHandler: this._afterSubmitHandler
          })
        })
        .catch((response) => {
          this.errorMessage = this._getErrorMessage(response)
        })
    },

    _afterSubmitHandler({ isSuccess, code, message }) {
      this.isLoading = false
      if (isSuccess) return this.$parent.close()

      if (code === 'credit_card_process_payment_failure') {
        this.errorMessage = message
      } else {
        this.$parent.close()
      }
    },

    _getPrime() {
      return new Promise((resolve, reject) => {
        TPDirect.card.getPrime((response) => {
          if (response.status != 0) {
            reject(response)
            return
          }
          resolve(response)
        })
      })
    },

    _getErrorMessage(response) {
      if (response.status === -5) {
        return this.messageLocaleText('user_credit_card_info.fill_error')
      } else {
        return response.msg
      }
    },

    _tryFetchUserCreditCards() {
      if (this.isUserSignedIn && this.userCreditCards.length === 0) {
        return this.$store.dispatch('users/fetchCreditCards')
      } else {
        return Promise.resolve(true)
      }
    },

    _setIsDefaultByUserCreditCards() {
      /* 第一次寫卡號的使用者，is_default 為 true */
      this.onsitePaymentData.is_default = this.userCreditCards.length === 0
    }
  }
}
// ex result of get prime
// {
//   status: response.status,
//   card: {
//     prime: response.card.prime,
//     issuer: response.card.issuer,
//     lastfour: response.card.lastfour,
//     bincode: response.card.bincode,
//     funding: response.card.funding,
//     type: response.card.type,
//     level: response.card.level,
//     country: response.card.country,
//     countrycode: response.card.countrycode
//   },
//   clientip: response.clientip,
//   card_identifier: response.card_identifier
// }
</script>
