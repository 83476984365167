import ProductCollectService from '../../../shared/services/product_collect_service.js'
import RegisterBanner from '../components/register/banner.vue'
import BackgroundSlide from '../components/common/background-slide.vue'
import ScopeTabs from '../components/register/scope-tabs.vue'
import Logo from '../../images/logo-h.png'
import Slogan from '../../images/register-slogan.svg'
import PasswordVisibilityMixin from '../components/mixins/password_visibility_mixin.js'

export default {
  name: 'register-container',

  components: {
    RegisterBanner,
    BackgroundSlide,
    ScopeTabs
  },

  mixins: [PasswordVisibilityMixin],

  // props: {},

  data() {
    return {
      isSignUpPage: false,
      policyAgreed: false,
      userScope: null,
      isUserLogin: false,
      isPartnerLogin: false,
      isPromoterMemberLogin: false
    }
  },

  computed: {
    signUpDisabled() {
      return !this.policyAgreed
    },

    isUserScope() {
      return this.userScope === 'user'
    },

    title() {
      switch (this.userScope) {
        case 'user':
          return this.actionLocaleText('user_login')
        case 'partner':
          return this.actionLocaleText('partner_login')
        case 'promoter_member':
          return this.actionLocaleText('promoter_login')
      }
    },

    loginPath() {
      switch (this.userScope) {
        case 'user':
          return '/user/login'
        case 'partner':
          return '/partner/login'
        case 'promoter_member':
          return '/promoters/login'
      }
    },

    signUpPath() {
      switch (this.userScope) {
        case 'user':
          return '/user/sign_up'
        case 'partner':
          return '/partner/sign_up'
        case 'promoter_member':
          return '/kol/sign_up'
      }
    },

    bannerUseType() {
      switch (this.userScope) {
        case 'user':
          return 'sign_up'
        case 'promoter_member':
          return 'promoter_sign_in'
        case 'partner':
          return 'partner_sign_in'
        default:
          return 'sign_up'
      }
    },

    bannerTitle() {
      switch (this.userScope) {
        case 'user':
          return '購物會員專屬'
        case 'promoter_member':
          return 'KOL / KOC 專屬'
        case 'partner':
          return '品牌主 專屬'
      }
    },

    bannerSubtitle() {
      switch (this.userScope) {
        case 'user':
          return '新奇！有趣！優惠！'
        case 'promoter_member':
          return '自媒體事業即刻變現！'
        case 'partner':
          return '帶貨 KOL 都在這！'
      }
    },

    signUpName() {
      switch (this.userScope) {
        case 'user':
          return '新用戶註冊'
        case 'promoter_member':
          return '新用戶註冊'
        case 'partner':
          return '新夥伴註冊'
      }
    },

    logoUrl() {
      return Logo
    },

    sloganUrl() {
      return Slogan
    }
  },

  // created() {},

  watch: {
    userScope: {
      handler(value) {
        this.updateQuery()

        this.checkLogin()
      }
    }
  },

  mounted() {
    this.isSignUpPage = this.$refs.container.dataset.isSignUpPage

    this.userScope = this.$refs.container.dataset.userScope
    this.isUserLogin = this.$refs.container.dataset.isUserLogin === '1'
    this.isPartnerLogin = this.$refs.container.dataset.isPartnerLogin === '1'
    this.isPromoterMemberLogin =
      this.$refs.container.dataset.isPromoterMemberLogin === '1'

    document.querySelector('.form-wrapper').classList.remove('is-hidden')
    if (document.querySelector('.banner-wrapper'))
      document.querySelector('.banner-wrapper').classList.remove('is-hidden')

    if (document.getElementById('local_collections'))
      this.$vlf
        .getItem(ProductCollectService.collectionKey())
        .then((localCollections) => {
          document.getElementById('local_collections').value =
            localCollections.join(',')
        })
    this.$nextTick(() => this._setRegisterWrapperMinHeight())
  },

  methods: {
    _setRegisterWrapperMinHeight() {
      const navbarHeight =
        document.querySelector('.site-navbar')?.offsetHeight || 0
      const footerHeight =
        document.querySelector('.site-footer')?.offsetHeight || 0

      document.querySelector(
        '.register-page'
      ).style.minHeight = `calc(100vh - ${navbarHeight + footerHeight}px)`
    },

    updateQuery() {
      if (this.isSignUpPage) return

      this.$store.dispatch('updateQueryString', {
        options: { replaceState: true },
        newQueryString: this.loginPath
      })
    },

    checkLogin() {
      switch (this.userScope) {
        case 'user':
          if (this.isUserLogin) window.location.href = this.loginPath
          break
        case 'promoter_member':
          if (this.isPromoterMemberLogin) window.location.href = this.loginPath
          break
        case 'partner':
          if (this.isPartnerLogin) window.location.href = this.loginPath
          break
      }
    }
  }
}
