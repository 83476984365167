<template lang="pug">
.vc-product-tag-filter.hidden(
  v-if="productTags.length > 0"
  style="display: none"
)
  span.text
    | {{ copyLocaleText('tag_filter') }} :
  b-checkbox-button(
    v-for="tag in productTags"
    :key="tag.id"
    v-model="tags"
    type="is-odd"
    :native-value="tag.name"
    @input="tagSelectedHandler"
  )
    .icon
      i.fa.fa-tag
    span {{ tag.name }}
</template>

<script>
import queryString from 'query-string'

export default {
  // components: {},
  // mixins: [],

  props: {
    allowAgeRestrictedTags: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      tags: []
    }
  },

  computed: {
    productTags() {
      return this.$store.getters['productTags/all']
    }
  },

  created() {
    let payload = {
      sort: 'position',
      filter: 'regular_content'
    }

    if (this.allowAgeRestrictedTags) delete payload['filter']

    this.$store
      .dispatch('productTags/all', payload)
      .then((_) => this.initTagFilters())
      .then((_) => this.$emit('update:tag-ready', true))
  },
  // mounted() {},
  methods: {
    tagSelectedHandler(tags) {
      this.$emit('update:tag-with', tags)
    },

    initTagFilters() {
      let decodedTagString = queryString.parse(window.location.search, {
        decode: false
      }).tag

      if (!decodedTagString) return

      this.tags = this.productTags
        .filter((tag) => {
          return decodedTagString
            .split(',')
            .map((tag) => decodeURIComponent(tag))
            .includes(tag.name)
        })
        .map((tag) => tag.name)

      this.tagSelectedHandler(this.tags)
    }
  }
}
</script>
