import PromoterAgreement from '../components/promoter/kol-agreement.vue'
import Form from 'odd-form_object'
import Promoter from '@models/promoter'
import NewFormFields from '@applicationComponents/promoter/new-form-fields.vue'
import PartnerLoginButton from '@applicationComponents/common/partner-login-button.vue'
import BackgroundSlide from '@applicationComponents/common/background-slide.vue'
import Slogan from '@application/images/register-slogan.svg'
import PasswordVisibilityMixin from '../components/mixins/password_visibility_mixin.js'

export default {
  name: 'promoter-register-container',

  components: {
    PromoterAgreement,
    NewFormFields,
    PartnerLoginButton,
    BackgroundSlide
  },

  mixins: [PasswordVisibilityMixin],

  // props: {}

  data() {
    return {
      policyAgreed: true,
      isPromoterAgreementActive: false,
      isFormLoaded: false,
      form: null
    }
  },

  computed: {
    signUpDisabled() {
      return !this.policyAgreed
    },

    isLoading() {
      return this.$store.getters['promoters/isLoading']
    },

    slogan() {
      return Slogan
    }
  },

  async mounted() {
    this.initForm()
  },

  methods: {
    initForm() {
      this.form = new Form(new Promoter())
      this.form.media_accounts = []
      this.form.sign_up_token = this.$el.dataset.token
      this.isFormLoaded = true
    },

    showAgreement() {
      this.isPromoterAgreementActive = true
    },

    async submitForm() {
      // 註冊由 api 完成，取代原本的 form submit 註冊
      // 此方式可以方便處理前端畫面
      await this.$store.dispatch('promoterMembers/signUp', {
        model: this.form.sync()
      })

      // 完成後 執行登入動作
      await this.$refs.form.submit()
    }
  }
}
