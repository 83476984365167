<template lang="pug">
.vc-dashboard-profile-verify-phone-button
  b-field
    b-input(
      type="text"
      inputmode="numeric"
      autocomplete="one-time-code"
      pattern="\d{6}"
      placeholder="123456"
      v-model="verificationCode"
      @input="errors.clear('verification_code')"
      required
    )
    .control
      .button.is-static
        countdown(
          :time="180000"
          :interval="1000"
          ref="countdown"
          @end="countdownEndedHandler"
        )
          template(slot-scope="props")
            | {{ props.minutes }}：{{ props.seconds }}
    .control
      .button.is-odd(
        @click="verifyPhone"
        :class="{ 'is-loading': isLoading }"
      )
        span
          | {{ actionLocaleText('confirm') }}
</template>

<script>
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    Countdown
  },

  // mixins: [],
  props: {
    errors: {
      type: Object,
      required: true
    },

    showVerifyPhoneButton: {
      type: Boolean,
      required: true
    },

    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      verificationCode: ''
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    }
  },

  // created() {},
  // mounted() {},

  methods: {
    countdownEndedHandler() {
      this.verificationCode = ''
      this.$emit('update:showVerifyPhoneButton', false)
    },

    verifyPhone() {
      this.$store
        .dispatch('users/verifyPhone', {
          model: this.user,
          verificationCode: this.verificationCode
        })
        .then(() => {
          this.$emit('update:showVerifyPhoneButton', false)
        })
    }
  }
}
</script>
