<template lang="pug">
.vc-dashboard-password-form
  .section-label
    .icon
      i.fa.fa-key
    span {{ actionLocaleText('change_password') }}

  .form-section.has-sub-fields
    b-field.is-full(
      :label="attributeLocaleText('user', 'current_password')"
      :type="errors.errorClassAt('password')"
      :message="errors.get('password')"
    )
      b-input(
        type="password"
        v-model="form.password"
        @input="errors.clear('password')"
      )

    b-field(
      :label="attributeLocaleText('user', 'new_password')"
      :type="errors.errorClassAt('new_password')"
      :message="errors.get('new_password')"
    )
      b-input(
        type="password"
        v-model="form.new_password"
        @input="errors.clear('new_password')"
      )
    b-field(
      :label="attributeLocaleText('user', 'password_confirmation')"
      :type="errors.errorClassAt('password_confirmation')"
      :message="errors.get('password_confirmation')"
    )
      b-input(
        type="password"
        v-model="form.password_confirmation"
        @input="errors.clear('password_confirmation')"
      )

  button.button.is-odd.is-block(
    @click="submitForm"
    :class="{ 'is-loading': isLoading }"
    :disabled="isLoading"
  )
    | {{ actionLocaleText('submit') }}

  hr(v-if="authServiceButtonInfo.length > 0")

  .auth-buttons
    a.button(
      v-for="(info, index) in authServiceButtonInfo"
      :key="index"
      :href="info.href"
      :class="info.buttonClass"
      :data-method="info.dataMethod"
      @click.prevent="info.action"
      :disabled="isLoading"
    )
      img(
        v-if="info.customIcon"
        :src="info.customIcon"
        :class="`is-${info.provider}`"
      )
      .icon(v-else)
        i.fa(:class="info.iconClass")
      span {{ info.buttonText }}
      .icon
        i.fa(:class="info.actionIconClass")
</template>

<script>
import UserForm from '../../../../shared/forms/user_form.js'
import LineIcon from '@application/images/line.png'
import LineIconWhite from '@application/images/line-w.png'

export default {
  // components: {},
  // mixins: [],
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: new UserForm(this.user)
    }
  },

  computed: {
    customIcons() {
      return {
        line: {
          binded: LineIcon,
          unbinded: LineIconWhite
        }
      }
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    errors() {
      return this.form.model.errors
    },

    authServiceEnabledMap() {
      return (
        this.$store.getters['siteConfigs/siteCustomDisplayConfig']
          .auth_service_enabled_map || {}
      )
    },

    availableAuthServices() {
      return Object.keys(this.authServiceEnabledMap).filter(
        (key) => this.authServiceEnabledMap[key]
      )
    },

    authServiceButtonInfo() {
      let userAuths = this.user.auths || []
      return this.availableAuthServices.map((provider) => {
        let bindedAuth = userAuths.find((auth) => auth.provider === provider)
        let serviceText = ` ${provider.toUpperCase()} ${this.actionLocaleText(
          'login'
        )}`
        let info = {
          provider: provider,
          buttonClass: { 'is-outlined': !!bindedAuth },
          iconClass: `fa-${provider}`,
          dataMethod: bindedAuth ? '' : 'post',
          buttonText: this.actionLocaleText(
            bindedAuth ? 'unbind_x' : 'bind_x',
            { x: serviceText }
          ),
          actionIconClass: bindedAuth ? 'fa-times' : 'fa-link',
          href: bindedAuth ? '#' : `/user/auth/${provider}`,
          customIcon: this.getCustonIcon(provider, !!bindedAuth),
          action: !!bindedAuth
            ? () => {
                if (this.isLoading) return
                this.$store
                  .dispatch('users/unbindAuthService', bindedAuth.id)
                  .then(() => {
                    return this.$store.dispatch('addFlashMessage', [
                      'success',
                      this.messageLocaleText('resource_deleted_successfully', {
                        resource: serviceText
                      })
                    ])
                  })
              }
            : () => {}
        }

        info.buttonClass[`is-${provider}`] = true

        return info
      })
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    getCustonIcon(provider, isBinded) {
      let key = [isBinded ? 'binded' : 'unbinded']
      let icons = this.customIcons[provider]
      if (icons) {
        return icons[key]
      }
    },

    submitForm() {
      this.$store.dispatch('users/save', this.form.sync()).then(() => {
        return this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText('password_update_successfully')
        ])
      })
    }
  }
}
</script>
