import CategoryMenu from '../../components/category/sidebar-category-menu.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'
import ProductList from '../../components/product/list.vue'

export default {
  name: 'products-index-page-container',

  components: {
    CategoryMenu,
    SlideshowContainer,
    ProductList
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      productCategoryId: undefined,
      selectedCategory: undefined,
      categoryMenuIsReady: false,
      bannerType: 'products'
    }
  },

  // computed: {},
  // created() {},

  async mounted() {
    this.productCategoryId = this.$el.dataset.category

    await this.tryRestoreScrollPosition()
  }
  // methods: {}
}
